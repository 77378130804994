import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Spheretherapy = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section">
                <h1 className="service-h1">Сферотерапия</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/endo.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p><strong>Сферотерапия (Endosphères Therapy, Сферотерапия терапия)</strong> - это инновационный метод в области косметологии, который использует специализированный аппарат с множеством микроцилиндров для проведения массажа и лимфодренажа на различных областях тела. Во время процедуры мягкие валики массажера надавливают и скатывают кожу и подкожные слои, стимулируя циркуляцию крови и лимфы, а также активируя процессы обновления тканей. Это помогает улучшить упругость кожи, снизить объемы, а также способствует снижению целлюлита.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/YaikXRVT4B4" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <p>Процедура "Сферотерапия" (Сферотерапия терапия) имеет несколько преимуществ, которые делают её популярным методом для улучшения состояния кожи, уменьшения целлюлита и подтяжки тканей.</p>
                    <div className="tabs-item">
                        <h3>Неинвазивность</h3>
                        <p>Процедура не включает хирургические вмешательства, инъекции или разрезы. Это безопасный и не травматичный метод.</p>
                    </div>

                    <div className="tabs-item">
                        <h3>Минимальное воздействие</h3>
                        <p>Сферотерапия использует массажные ролики для воздействия на кожу и ткани. Это позволяет достичь результатов без повреждения кожи.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Улучшение текстуры кожи</h3>
                        <p>Процедура помогает стимулировать кровообращение и лимфодренаж, что приводит к улучшению текстуры и цвета кожи.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Уменьшение целлюлита</h3>
                        <p>Сферотерапия помогает снизить видимость целлюлита, улучшая микроциркуляцию и стимулируя обменные процессы в тканях.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Подтяжка и упругость</h3>
                        <p>Массажные движения и вакуумный эффект способствуют подтяжке кожи и тканей, делая их более упругими.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Лимфодренаж</h3>
                        <p>Процедура помогает улучшить лимфодренаж, что способствует выводу токсинов и лишней жидкости из организма.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Местное воздействие</h3>
                        <p>Процедура может быть направлена на конкретные области, которые требуют коррекции, такие как бедра, ягодицы, живот или другие.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Подходит для разных типов кожи</h3>
                        <p>Процедура может быть применена к разным типам кожи, что делает её доступной для широкой аудитории.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Улучшение кровообращения:</h3>
                        <p>Массажные движения помогают стимулировать кровообращение и питание тканей, что может способствовать их оздоровлению.</p>
                    </div>

                    <div className="tabs-item">
                        <h3>Релаксация</h3>
                        <p>Многие клиенты ощущают приятное расслабление и комфорт во время процедуры, что делает её не только эффективной, но и приятной.</p>
                    </div>

                    <div className="tabs-item">
                        <h3>Комплексный подход</h3>
                        <p>Процедура может сочетаться с другими методами омоложения и коррекции, что позволяет достичь более полного и индивидуального результата.</p>
                    </div>

                </div>

                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                    <p>RF-лифтинг может быть рекомендован для ряда косметических проблем и ситуаций. Вот некоторые из показаний для проведения процедуры RF-лифтинга:</p>

                    <div className="tabs-item">
                        <h3>Целлюлит</h3>
                        <p>Сферотерапия широко используется для уменьшения целлюлита, особенно на бедрах, ягодицах, животе и других областях.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Подтяжка кожи</h3>
                        <p>Процедура помогает улучшить упругость и тонус кожи, особенно на областях, склонных к обвисанию.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Улучшение текстуры</h3>
                        <p>Сферотерапия способствует улучшению текстуры кожи, делая её более гладкой и ровной.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Стимуляция обмена веществ</h3>
                        <p>Процедура может помочь стимулировать обменные процессы в подкожных тканях, что может способствовать снижению объемов.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Улучшение микроциркуляции</h3>
                        <p>Массажные движения помогают стимулировать кровообращение и лимфодренаж, что способствует улучшению состояния тканей.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Уменьшение отеков</h3>
                        <p>Сферотерапия помогает уменьшить отеки и избыточную жидкость в тканях.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Постинъекционный и постоперационный уход</h3>
                        <p>Процедура может быть применена в качестве поддерживающей терапии после инъекций или хирургических вмешательств.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Подготовка косметических процедур</h3>
                        <p>Сферотерапия может быть использована для улучшения состояния кожи перед другими косметическими процедурами.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Улучшение общего состояния кожи</h3>
                        <p>Процедура придает коже более свежий и сияющий вид.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Коррекция областей с жировыми отложениями</h3>
                        <p>В ряде случаев Сферотерапия может быть использована для уменьшения объемов в областях с небольшими жировыми отложениями.</p>
                    </div>
                    <div className="tabs-item">
                        <h3></h3>
                        <p></p>
                    </div>
                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>

                    <p>Процедура "Сферотерапия" может быть эффективной и безопасной, однако есть определенные противопоказания, при которых проведение этой процедуры может быть нежелательным. Вот некоторые из них:</p>

                    <div className="tabs-item">
                        <h3>Воспалительные заболевания кожи</h3>
                        <p>Любые активные воспаления, инфекции или заболевания кожи в области, которую планируется обрабатывать.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Тромбоз и нарушения кровообращения</h3>
                        <p>Людям с тромбозами, нарушениями кровообращения, варикозами и другими кровеносными заболеваниями может быть нежелательно проведение процедуры.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Проблемы с сердцем</h3>
                        <p>Людям с проблемами с сердечными, стентами, кардиостимуляторами и другими серьезными сердечными состояниями следует избегать процедуры или получить согласие врача.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Онкологические заболевания</h3>
                        <p>Людям, страдающим онкологическими заболеваниями, следует избегать процедуры, особенно если она проводится в районах с опухолями.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Беременность и лактация</h3>
                        <p>Процедура не рекомендуется беременным и кормящим женщинам из-за возможных воздействий на организм.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Системные заболевания</h3>
                        <p>Людям с системными заболеваниями, такими как коллагенозы или аутоиммунные заболевания, рекомендуется консультация с врачом перед проведением процедуры.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Металлические имплантаты</h3>
                        <p>Наличие металлических имплантатов в области обработки может быть противопоказанием.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Иммунодепрессивные состояния</h3>
                        <p>Людям с иммунодепрессивными состояниями, в том числе приемом иммуносупрессоров, может потребоваться особая осторожность.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Сахарный диабет</h3>
                        <p>Пациентам с сахарным диабетом может потребоваться дополнительный мониторинг и контроль при проведении процедуры.</p>
                    </div>
                    <div className="tabs-item">
                        <h3>Индивидуальные особенности</h3>
                        <p>Ваш медицинский контекст и другие индивидуальные особенности также могут повлиять на возможность проведения процедуры.</p>
                    </div>

                    <p>Это не полный список противопоказаний, и они могут различаться в зависимости от медицинского состояния человека. Перед принятием решения о проведении криолиполиза рекомендуется провести консультацию с медицинским специалистом.</p>
                </div>

                <p className='recommendation'>Победите целлюлит, подтяните кожу, и насладитесь гладкой текстурой с помощью Сферотерапии — передовой технологии массажа.</p>


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/endo-how-to.jpg" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <div className="tabs-item">
                                <h3>Подготовка</h3>
                                <p>Специалист проведет предварительную консультацию с вами, чтобы уточнить ваши цели и ожидания от процедуры. Если нет медицинских противопоказаний, специалист начнет подготовку к процедуре.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Подготовка кожи</h3>
                                <p>Область, которую вы хотите обработать (например, бедра, ягодицы, живот), будет тщательно очищена от макияжа, кремов и загрязнений.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Применение геля</h3>
                                <p>На кожу наносится специальный гель, который улучшает скольжение роликов и уменьшает трение, защищая кожу от возможного раздражения.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Процедура "Сферотерапия"</h3>
                                <p>Манипула аппарата будет плавно скользить по обрабатываемой области, выполняя массажные движения.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Ощущения</h3>
                                <p>Во время процедуры вы можете ощущать приятное покалывание, массаж и потягивание кожи.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Длительность сеанса</h3>
                                <p>Время, необходимое для завершения сеанса, может варьироваться, но обычно это занимает около 30-85 минут.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Завершение</h3>
                                <p>По окончании процедуры гель удаляется с кожи, и вы можете ощутить легкость и освежающий эффект.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Рекомендации по уходу</h3>
                                <p>Специалист может дать вам рекомендации по дальнейшему уходу за кожей после процедуры, включая рекомендации по увлажнению и защите от солнца.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Регулярность сеансов</h3>
                                <p>Рекомендуется проводить серию сеансов для достижения наилучших результатов. Частота может зависеть от ваших целей и рекомендаций специалиста.</p>
                            </div>

                            <p>Важно помнить, что результаты криолиполиза будут проявляться со временем, поскольку разрушенные жировые клетки постепенно выводятся из организма. Могут потребоваться повторные сессии для достижения наилучших результатов. Процедура проводится под контролем эститиста по телу.</p>

                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <p>
                                Результаты процедуры "Сферотерапия" заметны уже после нескольких проведенных сеансов, но они будут зависеть от индивидуальных особенностей  человека, состояния кожи и целей, а также от количества и регулярности проведения процедур. Вот некоторые результаты, которые можно ожидать:
                            </p>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение целлюлита:</strong> Одним из основных результатов является уменьшениеъ целлюлита, особенно на бедрах, ягодицах, животе и других проблемных зонах.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Подтяжка и упругость:</strong> Процедура способствует повышению упругости кожи и подтяжке тканей, делая области тела более подтянутыми и молодыми.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение текстуры:</strong> Кожа становится более гладкой, ровной и однородной в текстуре.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение цвета кожи:</strong> Массажные движения стимулируют кровообращение и помогают коже приобрести более здоровый и сияющий вид.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение лимфодренажа:</strong> Процедура способствует более эффективному удалению токсинов и избыточной жидкости из тканей благодаря улучшенному лимфодренажу.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Релаксация и комфорт:</strong> Многие клиенты ощущают расслабление и комфорт во время процедуры, что также способствует общему ощущению благополучия.</p>
                            </div>

                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение местной микроциркуляции:</strong> Процедура стимулирует местное кровообращение, что может помочь улучшить питание тканей.</p>
                            </div>

                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Подготовка к другим процедурам:</strong> "Сферотерапия" может использоваться как подготовительная стадия перед другими косметическими процедурами.</p>
                            </div>

                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Увеличение уверенности:</strong> Улучшение внешнего вида и состояния кожи может способствовать повышению самооценки и уверенности.</p>
                            </div>

                            <p>
                                Наилучшие результаты обычно достигаются после серии сеансов, которые проводятся с определенным интервалом. Рекомендуется проконсультироваться с эстетистом по телу для определения оптимального количества сеансов.
                            </p>
                        </div>

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div class="tabs-item">
                                <div class="beforeafter_container">
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>До</p>
                                    </div>
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='faq-container'>
                            <div>
                                <h3>Часто задаваемые вопросы</h3>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-1">Что такое процедура "Сферотерапия"?</button>
                                <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Сферотерапия" - это методика массажа воздействующего на кожу и ткани с использованием специального оборудования с множеством микроцилиндров.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-2">Для чего используется "Сферотерапия"?</button>
                                <div class="panel" style={faqTab === 'faq-2' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Процедура используется для уменьшения целлюлита, подтяжки кожи, улучшения текстуры и цвета кожи, а также для стимуляции кровообращения и лимфодренажа.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-3">Как долго длится сеанс "Сферотерапии"?</button>
                                <div class="panel" style={faqTab === 'faq-3' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Продолжительность сеанса может варьироваться от 30 до 85 минут в зависимости от области обработки.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-4">Сколько сеансов обычно требуется для видимых результатов?</button>
                                <div class="panel" style={faqTab === 'faq-4' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Рекомендуется проводить серию сеансов, обычно от 6 до 12, для достижения наилучших результатов.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-5">Чувствую ли я боль или дискомфорт во время процедуры?</button>
                                <div class="panel" style={faqTab === 'faq-5' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Процедура не вызывает болезненных ощущений - клиенты отмечают приятное массажное воздействие.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-6">Какие результаты я могу ожидать после процедуры?</button>
                                <div class="panel" style={faqTab === 'faq-6' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Улучшение текстуры кожи, уменьшение целлюлита и подтяжку тканей. Однако результаты могут варьировать в зависимости от индивидуальных факторов.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-7">Существуют ли противопоказания для "Сферотерапии"?</button>
                                <div class="panel" style={faqTab === 'faq-7' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Да, есть определенные медицинские состояния, беременность и другие обстоятельства, при которых процедура может быть нежелательной.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-8">Как часто мне следует проводить сеансы?</button>
                                <div class="panel" style={faqTab === 'faq-8' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Частота сеансов зависит от ваших целей и рекомендаций специалиста, но обычно это может быть 2-3 раза в неделю.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-9">Когда я увижу результаты?</button>
                                <div class="panel" style={faqTab === 'faq-9' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Некоторые результаты могут быть заметны сразу после первых сеансов, но для полных и долгосрочных результатов потребуется завершить всю серию процедур (6-12).</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-10">Могу ли я комбинировать "Сферотерапию" с другими процедурами?</button>
                                <div class="panel" style={faqTab === 'faq-10' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Да, в зависимости от ваших целей и рекомендаций специалиста, "Сферотерапия" может комбинироваться с другими процедурами для максимальных результатов.</p>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </section>
            <section className='services-section'>
                <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>Прессотерапия</h3>
                    <a href='/Presstherapy' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

                <div className='relevant-item'>
                    <h3>Обертывание</h3>
                    <a href='/Covering' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

                <div className='relevant-item'>
                    <h3>RF-Лифтинг</h3>
                    <a href='/RFLifting' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>


            </section>
            <Footer />
        </>

    );
};


export default Spheretherapy;