import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const EMSCULPT = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
        console.log(e.target.id);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section">
                <h1 className="service-h1">EMsculpt</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p>Аппарат EMSCULPT – это инновационная неинвазивная процедура для укрепления мышц и снижения жира, использующая электромагнитные импульсы. EMSCULPT - это новейший инновационный продукт, который произвел революцию в области неинвазивной коррекции фигуры.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/nbCpLIaeH2o" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>

                    <ul>
                        <li className="tabs-item">
                            <h3>Уникальность</h3>
                            <p>Аппарат предназначен для улучшения тонуса и укрепления мышц живота, а также для формирования объема и
                                укрепления мышц ягодиц путем воздействия абсолютно новой технологии - высокоинтенсивной сфокусированной
                                электромагнитной терапии HIFEM.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Эффективность</h3>
                            <p>Технология HIFEM, реализованная в аппарате EMSCULPT, вызывает около 20 000 непроизвольных сокращений мышц за
                                1 процедуру. Такой вид мышечных сокращений является супрамаксимальным, а значит его невозможно получить путем
                                тренировок, а также произвольного напряжения и расслабления мышц.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Укрепление мышц</h3>
                            <p>EMsculpt способствует глубоким и сильным мышечным сокращениям, которые подобны интенсивным тренировкам. Это позволяет укрепить мышцы, улучшить их тонус и форму.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Снижение жира</h3>
                            <p>Помимо укрепления мышц, процедура может также способствовать снижению жира в области, где она проводится. Это происходит благодаря увеличению метаболической активности мышц.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Неинвазивное воздействие</h3>
                            <p>Процедура проходит безболезненно и не требует анестезии. Во время процедуры чувствуются интенсивные, но безболезненные мышечные сокращения в обрабатываемой зоне.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Работает на проблемные зоны</h3>
                            <p>Улучшение тонуса мышца пресса и ягодиц показано большинству пациентов.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Видимый результат</h3>
                            <p>Прогрессирующий после завершения курса процедур.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Доказанная эффективность</h3>
                            <p>Множество клинических исследований подтвердили эффективность EMsculpt в укреплении мышц и снижении жира.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Безопасность</h3>
                            <p>Подтверждена 7 независимыми клиническими многоцентровыми исследованиями, проведенными в США. Терапия не относится к термическим, ионизирующим или лучевым</p>
                        </li>
                    </ul>
                </div>

                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                    <ul>

                        <li className="tabs-item">
                            <h3>Желание укрепить мышцы</h3>
                            <p>EMsculpt эффективно помогает укрепить мышцы в различных областях тела, таких как живот, ягодицы, бедра и руки.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Желание снизить жировую массу</h3>
                            <p>Процедура также способствует снижению жира в области, где она проводится, благодаря активизации метаболизма мышц.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Улучшение тонуса и формы</h3>
                            <p>EMsculpt может помочь улучшить общий тонус кожи и форму тела, делая ее более подтянутой и упругой.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Желание улучшить внешний вид</h3>
                            <p>Если вы хотите улучшить внешний вид конкретных областей тела, где тренировки могут быть менее эффективными.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Восстановление после беременности</h3>
                            <p>EMsculpt может быть полезной для женщин, стремящихся вернуть тонус мышц после беременности и родов.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Улучшение послеоперационного восстановления</h3>
                            <p>Процедура может помочь улучшить мышечный тонус и восстановление после хирургических вмешательств.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Замена или дополнение физической активности</h3>
                            <p>EMsculpt может быть вариантом для тех, кто имеет ограниченное время для тренировок или имеет трудности с физической активностью.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Желание быстрых результатов</h3>
                            <p>Если вы ищете относительно быстрые результаты в укреплении мышц и снижении жира.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Поддержание общей физической формы</h3>
                            <p>EMsculpt может быть использована для поддержания тонуса и формы тела после достижения желаемых результатов.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Желание преодолеть плато в тренировках</h3>
                            <p>EMsculpt может помочь преодолеть плато в тренировках и продвинуться дальше в достижении ваших физических целей.</p>
                        </li>

                    </ul>
                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                    <ul>
                    <p className='warning'>EMsculpt - это относительно безопасная процедура, но у нее могут быть определенные противопоказания. Важно проконсультироваться с медицинским специалистом перед началом процедуры, чтобы убедиться, что она безопасна для вас. Вот некоторые из возможных противопоказаний:</p>
                    <li className="tabs-item">
                        <h3>Сердечно-сосудистые заболевания</h3>
                        <p>Людям с серьезными заболеваниями сердца, такими как аритмии, стенокардия или сердечная недостаточность, может быть не рекомендована процедура.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Электронные имплантаты</h3>
                        <p>Людям с имплантированными электронными устройствами, такими как кардиостимуляторы или внутренние слуховые аппараты, может быть не рекомендована процедура из-за возможного воздействия электромагнитных полей.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Беременность и лактация</h3>
                        <p>Процедура EMsculpt не рекомендуется для беременных женщин и тех, кто находится в период лактации.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Операционные швы и раны</h3>
                        <p>Людям, имеющим незажившие раны или операционные швы в области процедуры, может быть рекомендовано отложить EMsculpt до полного восстановления.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Тяжелые заболевания или инфекции</h3>
                        <p>Людям с тяжелыми инфекционными заболеваниями или обострением хронических заболеваний может потребоваться отсрочка процедуры.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Эпилепсия</h3>
                        <p>Людям, страдающим от эпилепсии, может быть не рекомендована процедура из-за возможного воздействия электромагнитных полей на состояние.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Железодефицитная анемия</h3>
                        <p>У людей с железодефицитной анемией, ухудшающей кровоснабжение мышц, процедура может быть нежелательной.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Опухоли или рак</h3>
                        <p>Процедура может не быть рекомендована людям с активными опухолями или онкологическими заболеваниями.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Ожоги или повреждения кожи</h3>
                        <p>Пациентам с ожогами, повреждениями кожи или другими кожными проблемами может потребоваться особая осторожность или отсрочка.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Иммуносупрессивная терапия</h3>
                        <p>Людям, принимающим иммуносупрессивные препараты, может быть не рекомендована процедура.</p>
                    </li>
                    </ul>
                </div>
                <p className='recommendation'>Будущее наступило! Уберем пивной живот без операции, без реабилитации, без изнурительных тренировок, всего за 1 месяц!</p>


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt-2.png" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <div className="tabs-item">
                                <p>Процедура длится 30 минут, проводится с интервалом 2-3 дня, количество процедур определяет эстетист по
                                    телу на первичной консультации.</p>
                            </div>
                            <div className="tabs-item">
                                <h3>Подготовка</h3>
                                <p>С обрабатываемой области необходимо снять одежду, а также снять с себя все элементы одежды и аксессуары, содержащие металл.</p>
                            </div>
                            <div className="tabs-item">
                                <h3>Устанопка аппликаторов</h3>
                                <p>Центры аппликаторов размещаются над обрабатываемой областью и находятся в контакте с кожей. Аппликаторы закрепляются с помощью фиксирующего ремня подходящего размера.</p>
                            </div>
                            <div className="tabs-item">
                                <h3>Начало процедуры</h3>
                                <p>Процедура проводится в соответствие с параметрами, выбранными эстетистом, и постепенно интенсивность процедуры увеличивается. По всей области воздействия проходят равномерные сокращения.</p>
                            </div>
                            <div className="tabs-item">
                                <h3>Регулировка параметров</h3>
                                <p>В ходе процедуры интенсивность увеличивается до достижения порога дискомфорта и поддерживается на уровне, который комфортен, но при этом вызывает наиболее сильные мышечные сокращения. </p>
                            </div>
                            <div className="tabs-item">
                                <h3>Контроль</h3>
                                <p>Эстетист регулярно проверяет порог чувствительности, слегка увеличивая интенсивность. При возникновении дискомфорта интенсивность временно снижается мастером.</p>
                            </div>
                            <div className="tabs-item">
                                <h3>Завершение процедуры</h3>
                                <p>После завершения цикла процедуры может ощущаться легкая мышечная усталость после процедуры.</p>
                            </div>
                            <div className="tabs-item">
                                <h3>Серия сеансов</h3>
                                <p>Для достижения оптимальных результатов обычно рекомендуется серия из нескольких сеансов, проводимых с интервалами в несколько дней.</p>
                            </div>
                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Укрепление мышц:</strong> Процедура способствует укреплению мышц в областях, где она проводится. Мышцы могут стать более тонкими, упругими и подтянутыми.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение тонуса кожи:</strong> После серии сеансов клиенты замечают улучшение тонуса и эластичности кожи, что делает ее более молодой и подтянутой.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Снижение жира:</strong> Процедура активизирует метаболизм в мышцах, что может способствовать снижению жира в области, где она проводится.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение внешнего вида:</strong> Клиенты могут заметить, что область, подвергшаяся воздействию EMsculpt, стала более подтянутой и выразительной.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение силы:</strong> Укрепление мышц может также повысить общую силу и выносливость в области, подвергшейся процедуре.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение объема:</strong> Некоторые клиенты могут заметить уменьшение объема в области процедуры, особенно в сочетании со снижением жира.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение послеоперационного восстановления:</strong> EMsculpt может помочь ускорить восстановление после хирургических вмешательств и улучшить общий результат операции.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Повышение самооценки:</strong> Улучшение внешнего вида и телесной формы может повысить уверенность и самооценку.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Преодоление плато в тренировках:</strong> EMsculpt может помочь преодолеть плато в тренировках и достичь новых результатов.</p>
                            </div>
                            <p className='advice'>Результаты EMsculpt обычно наблюдаются после проведения нескольких сеансов, и для достижения наилучших результатов может потребоваться серия процедур. Перед началом процедуры рекомендуется консультироваться с медицинским специалистом</p>
                        </div>

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div class="tabs-item">
                                <div class="beforeafter_container">
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>До</p>
                                    </div>
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </section>
            <section className='services-section'>
                <div className='faq-container'>
                    <div>
                        <h3>Часто задаваемые вопросы</h3>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-1">Что такое EMsculpt?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-1' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                            <p>EMsculpt - это инновационная процедура, использующая электромагнитные импульсы для укрепления мышц и снижения жира в определенных областях тела.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-2">Как работает EMsculpt?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-2' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-2' ? { display: 'block' } : { display: 'none' }}>
                            <p>Процедура работает путем создания электромагнитных импульсов, которые стимулируют мышечные сокращения в целевой области, подражая интенсивной физической активности.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-3">Какие области тела можно подвергнуть воздействию EMsculpt?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-3' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-3' ? { display: 'block' } : { display: 'none' }}>
                            <p>EMsculpt может применяться для укрепления мышц и снижения жира в областях, таких как живот, ягодицы, бедра и руки.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-4">Какие результаты можно ожидать от EMsculpt?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-4' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-4' ? { display: 'block' } : { display: 'none' }}>
                            <p>Результаты могут включать укрепление мышц, улучшение тонуса кожи, снижение жира и улучшение внешнего вида в области процедуры.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-5">Сколько сеансов требуется для видимых результатов?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-5' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-5' ? { display: 'block' } : { display: 'none' }}>
                            <p>Обычно рекомендуется провести серию из нескольких сеансов для достижения наилучших результатов. Обычно это 4-6 сеансов, проводимых через несколько недель.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-6">Чувствуются ли боли во время процедуры?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-6' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-6' ? { display: 'block' } : { display: 'none' }}>
                            <p>Во время процедуры EMsculpt могут быть ощущения сильных мышечных сокращений, но это не должно быть болезненным. Некоторые люди ощущают легкое непривычное ощущение.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-7">Как долго длится каждый сеанс EMsculpt?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-7' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-7' ? { display: 'block' } : { display: 'none' }}>
                            <p>Продолжительность сеанса обычно составляет 30 минут.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-8">Когда можно ожидать первые результаты?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-8' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-8' ? { display: 'block' } : { display: 'none' }}>
                            <p>Некоторые люди могут заметить первые изменения после первых сеансов, но более заметные результаты обычно становятся видимыми после завершения всей серии процедур.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-9">Существуют ли противопоказания к EMsculpt?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-9' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-9' ? { display: 'block' } : { display: 'none' }}>
                            <p>Да, есть определенные медицинские состояния и заболевания, при которых процедура может быть нежелательной. Важно проконсультироваться с медицинским специалистом перед началом процедуры.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-10">Как долго будут сохраняться результаты EMsculpt?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-10' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-10' ? { display: 'block' } : { display: 'none' }}>
                            <p>Результаты могут сохраняться в течение нескольких месяцев после завершения серии сеансов. Для поддержания долгосрочных результатов иногда рекомендуется периодические повторные сеансы.</p>
                        </div>
                    </div>


                </div>
            </section>
            <section className='services-section'>
            <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>Прессотерапия</h3>
                    <a href='/Presstherapy' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>LPG массаж</h3>
                    <a href='/LPG' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

            </section>
            <Footer />
        </>

    );
};


export default EMSCULPT;