import React from 'react';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

const Abonement = () => {

   
    return (
        <>
        <NavigationDesk />
        <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section addMargin">
              
                <h1 className="service-h1">Продление абонементов</h1>
                <div className="services-video-descr-img">
                    {/* <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt.png" />
                    </div> */}
                    <div className="service-video-descr">
                        <div className="service-description">
                           <p>В нашем салоне вы сможете продлить и приобрести абонементы на процедуры.</p>
                        </div>
                        {/* <div className="service-youtube-video">
                            <iframe width="100%" height="500" src="https://www.youtube.com/embed/vr7mkSiKRLM" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div> */}
                    </div>
                </div>
            </section>
            <Footer />
            
        </>

    );
};


export default Abonement;