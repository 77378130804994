import React from 'react';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

const Massage = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section">
                <h1 className="service-h1">Ручной массаж</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p><strong>Ручной массаж</strong> - одна из самых приятных и релаксирующих процедур в современном мире. Ручной массаж может использоваться в качестве дополнительной процедуры для помощи в процессе похудения и избаления от целлюлита, но следует помнить, что массаж сам по себе не является методом для активного сжигания жира и потери веса.</p>
                            <p>Конечно, аппаратный массаж так же полезен, как и ручной, но только руки мастера позволяют расслабиться или взбодриться, повысить тонус мышц или оказать на них седативное воздействие, подтянуть тургор кожи и улучшить лимфоток, снять боль в теле и даже бороться с лишними объемами и килограммами. И, самое главное, опытный массажист тактильно почувствует проблемную зону в организме и будет заниматься ею, оказывая отдельное внимание: аккуратно прорабатывать с разной степенью воздействия до улучшения состояния.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/fqLL0rRyJqA?si=SBZccAA5T3rHLfnh" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    {/* <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
            <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button> */}
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <ul>

                        <p><strong>Лазерная эпиляция</strong> имеет ряд преимуществ, которые делают этот метод удаления волос самым популярным и эффективным. Вот некоторые из главных преимуществ лазерной эпиляции:</p>



                        <li className="tabs-item">
                            <h3>Персональная адаптация</h3>
                            <p>Ручной массаж позволяет массажисту адаптировать процедуру под индивидуальные потребности клиента. Массажист может настраивать интенсивность, технику и давление, исходя из состояния мышц и предпочтений клиента.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Более глубокое воздействие</h3>
                            <p>Ручной массаж может обеспечить более глубокое и точечное воздействие на проблемные области, что особенно важно при снятии мышечных натяжений и боли.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Улучшение циркуляции крови</h3>
                            <p>Ручной массаж может улучшить кровообращение в областях, которые подвергаются массажу. Это может помочь увеличить поступление кислорода и питательных веществ к мышцам, что может способствовать более эффективному тренировочному процессу и уменьшить мышечное напряжение.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Уменьшение отечности</h3>
                            <p>Массаж может помочь уменьшить отечность, которая может возникнуть после интенсивных тренировок. Улучшение лимфатического дренажа может помочь вывести излишнюю жидкость из тканей.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Разбивание жировых отложений</h3>
                            <p>Хотя массаж сам по себе не способен сжигать жир, некоторые техники массажа, такие как лимфодренажный массаж и массаж с использованием вакуумных банок, могут помочь улучшить циркуляцию в областях с жировыми отложениями. Это может сделать кожу более упругой и улучшить внешний вид.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Психологическое воздействие</h3>
                            <p>Расслабляющий эффект массажа может помочь снизить стресс и уровень тревожности, что может привести к более здоровым пищевым привычкам и управлению эмоциональным перееданием.</p>
                        </li>



                        <p>Важно понимать, что для достижения заметных результатов в похудении требуется сочетание физической активности, здорового питания и, в некоторых случаях, медицинских консультаций. Ручной массаж может дополнить эти меры, но не является единственным решением для похудения. Перед началом любых процедур по похудению, включая массаж, рекомендуется проконсультироваться с врачом или специалистом по здоровому образу жизни.</p>
                    </ul>

                </div>

                {/* <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                                
                <div className="tabs-item">
                    <h3></h3>
                    <p></p>
                </div>
              

                <p>Важно отметить, что у процедуры есть противопоказания и ограничения, такие как беременность, наличие металлических имплантатов в области обработки, серьезные медицинские состояния и другие факторы, которые могут влиять на безопасность и эффективность процедуры. Поэтому важно проконсультроваться с врачем перед процедурой.</p>
               
            </div> */}

                {/* <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                            
                <div className="tabs-item">
                    <h3></h3>
                    <p></p>
                </div>

            </div> */}


            </section>
            <Footer />
        </>

    );
};


export default Massage;