import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Presstherapy = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section">
                <h1 className="service-h1">Прессотерапия</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/press.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p><strong>Прессотерапия (или лимфодренаж, пневмомассаж)</strong> – это вид аппаратного массажа, суть которого заключается в механическом воздействии на тело сжатым воздухом. Возможно воздействие как на все тело, так и на отдельные части: руки, ноги, поясницу, живот. Аппарат имеет специальное программное обеспечение для регулирования интенсивности и времени давления и подогревом. Клиент не ощущает во время процедуры дискомфорта или боли, напротив, по телу разливается тепло, мышцы расслабляются.</p>
                            <p>Прессотерапия эффективна при целлюлите, для снижения массы тела, при дряблой коже. Эта методика часто назначается после травм, инсульта, оперативных вмешательств, при проблемах с суставами.</p>
                            <p>Для выполнения процедуры на человека надевают специальный костюм, в который под давлением нагнетается воздух. Ритмичное сдавление тела, которое чередуется с эпизодами расслабления, стимулирует кровообращение, вытесняет скопившуюся в межклеточном пространстве жидкость и ускоряет движение лимфы по сосудам, запуская процесс похудения.</p>
                            <p>Прессотерапия изначально была создана для лечения варикоза. Она хорошо укрепляет сосудистые стенки, нормализует кровоток в конечностях. Сегодня сфера ее применения гораздо шире – новое решение для старых проблем.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/35U5YlX72pQ" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <ul>

                        <p className='recommendation'>Процедура "Эндосфера" (Эндосфера терапия) имеет несколько преимуществ, которые делают её популярным методом для улучшения состояния кожи, уменьшения целлюлита и подтяжки тканей.</p>

                        <li className="tabs-item">
                            <h3>Стимуляция кровообращения</h3>
                            <p>Процедура улучшает кровообращение в обрабатываемых областях, что способствует более эффективному поступлению кислорода и питательных веществ к тканям.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Улучшение лимфодренажа</h3>
                            <p>Прессотерапия помогает улучшить лимфодренаж, что способствует более эффективному удалению токсинов и отходов из организма.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Уменьшение отеков</h3>
                            <p>Процедура может помочь снять отеки и избыточную жидкость из тканей, особенно в нижних конечностях.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Улучшение внешнего вида кожи</h3>
                            <p>Прессотерапия способствует улучшению текстуры и цвета кожи, делая её более гладкой и сияющей.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Уменьшение целлюлита</h3>
                            <p>Процедура помогает уменьшить видимость целлюлита за счет стимуляции обменных процессов в тканях.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Расслабление и снятие напряжения</h3>
                            <p>Мягкий массаж и ритмические движения воздушных манжет помогают расслабить мышцы и снять напряжение.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Ощущения легкости</h3>
                            <p>Процедура способствует улучшению ощущения легкости и свежести в конечностях.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Улучшение общего состояния</h3>
                            <p>Прессотерапия способствует общему улучшению самочувствия и ощущения внутреннего комфорта.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Поддержка после хирургических вмешательств</h3>
                            <p>Прессотерапия может быть рекомендована в качестве дополнительного метода восстановления после хирургических вмешательств, таких как липосакция.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Подготовка к спортивным мероприятиям</h3>
                            <p>Процедура может помочь подготовить мышцы к физической активности, улучшая их готовность и предотвращая мышечные спазмы.</p>
                        </li>


                        <p>Важно заметить, что результаты могут варьировать в зависимости от индивидуальных характеристик и целей человека. Перед проведением прессотерапии рекомендуется проконсультироваться с медицинским специалистом, особенно если у вас есть медицинские противопоказания или состояния, требующие особой осторожности.</p>
                    </ul>

                </div>

                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>

                    <ul>
                        <p className='recommendation'>RF-лифтинг может быть рекомендован для ряда косметических проблем и ситуаций. Вот некоторые из показаний для проведения процедуры RF-лифтинга:</p>

                        <li className="tabs-item">
                            <h3>Лимфатический застой</h3>
                            <p>Прессотерапия может быть полезной для тех, у кого есть проблемы с оттоком лимфы, что может вызывать отечность и дискомфорт.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Целлюлит</h3>
                            <p>Прессотерапия может помочь улучшить циркуляцию крови и лимфы, что может способствовать снижению внешнего проявления целлюлита.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Тяжелые ноги</h3>
                            <p>Люди, страдающие от ощущения тяжести и усталости в ногах из-за нарушений кровообращения или лимфатической недостаточности, могут получить облегчение от прессотерапии.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Послеоперационное восстановление</h3>
                            <p>Прессотерапия может помочь ускорить заживление и уменьшить отечность после хирургических вмешательств, таких как пластическая хирургия или операции на сосудах.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Синдром "тяжелых ног" при беременности</h3>
                            <p>Прессотерапия может быть эффективной для снятия дискомфорта и отечности у беременных женщин.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Стимуляция общей циркуляции</h3>
                            <p>Процедура может помочь стимулировать кровообращение, что способствует общему улучшению здоровья и внешнего вида кожи.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Поддержание тонуса кожи</h3>
                            <p>Прессотерапия может помочь улучшить эластичность и тонус кожи.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Реабилитация после травм</h3>
                            <p>В некоторых случаях прессотерапия может помочь восстановлению мышц и суставов после травм и повреждений.</p>
                        </li>

                        <p>Перед применением прессотерапии вам следует проконсультироваться с медицинским специалистом, так как в зависимости от вашего состояния и медицинской истории прессотерапия может быть не рекомендована.</p>
                    </ul>
                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                    <ul>
                        <p className="warning">Прессотерапия может быть эффективной процедурой для многих людей, но есть некоторые противопоказания, при которых она может быть небезопасной или не рекомендуется. Вот некоторые из основных противопоказаний к прессотерапии:</p>

                        <li className="tabs-item">
                            <h3>Сердечно-сосудистые заболевания</h3>
                            <p>Прессотерапия может усилить кровоток и кровяное давление, поэтому она может быть противопоказана при серьезных сердечно-сосудистых заболеваниях, таких как гипертония, стенокардия, нарушения ритма сердца и др.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Тромбоз и тромбофлебит</h3>
                            <p>Процедура может способствовать перемещению тромбов и увеличить риск осложнений в случае наличия тромбоза или тромбофлебита.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Онкологические заболевания</h3>
                            <p>Прессотерапия может повлиять на распространение раковых клеток или вызвать дополнительное давление на опухоль, поэтому она обычно не рекомендуется при онкологии.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Хирургические швы и раны</h3>
                            <p>Процедура может оказать негативное воздействие на заживление ран, швов или ожидающих операцию областей.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Венозные язвы и сосудистые нарушения</h3>
                            <p>Прессотерапия может усилить венозное давление и ухудшить состояние пациентов с венозными язвами и другими сосудистыми нарушениями.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Острые инфекционные заболевания</h3>
                            <p>Во время инфекционного процесса прессотерапия может усилить циркуляцию микробов и токсинов, ухудшая общее состояние пациента.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Открытые раны или воспаления</h3>
                            <p>Процедура может вызвать дополнительное раздражение и усилить воспалительные процессы в областях с открытыми ранами или воспалениями.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Беременность</h3>
                            <p>Прессотерапия может быть небезопасной для беременных женщин, особенно в начальных стадиях беременности.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Тяжелые хронические заболевания</h3>
                            <p>Пациентам с тяжелыми хроническими заболеваниями следует проконсультироваться с врачом о возможности прессотерапии.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Неспецифицированные заболевания и состояния</h3>
                            <p>Если у вас есть какие-либо медицинские состояния, которые могут быть ухудшены или усилиться в результате прессотерапии, следует обязательно проконсультироваться с медицинским специалистом перед началом процедуры.</p>
                        </li>

                        <p>Важно обратиться к врачу или квалифицированному специалисту до проведения прессотерапии, чтобы оценить ваше состояние и определить, является ли эта процедура безопасной и рекомендуемой для вас.</p>
                    </ul>
                </div>

                <p className='recommendation'>Откройте путь к легкости и красоте с прессотерапией – инновационным способом улучшить циркуляцию, убрать отечность и достичь идеального контура тела! </p>


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/press+covering-how-to.png" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <div className="tabs-item">
                                <h3>Подготовка</h3>
                                <p>За 2-3 часа до начала сеанса необходимо закончить прием пищи, пить нужно как можно больше жидкости. Однако, к началу процедуры мочевой пузырь и желудок должны быть пустыми.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Надевание манжет</h3>
                                <p>Манжеты, подключенные к аппарату, будут надеваться на различные части вашего тела, такие как ноги, бедра, руки и живот, в зависимости от ваших целей и потребностей.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Запуск аппарата</h3>
                                <p>Манжеты начнут надуваться и сжиматься последовательно, создавая волну сжатия, которая перемещается вверх по вашему телу. Этот процесс повторяется в заданных интервалах времени. При желании специалист включает подогрев.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Интенсивность и продолжительность</h3>
                                <p>Специалист настроит интенсивность сжатия, длительность процедуры и подогрев в соответствии с вашими потребностями, пожеланиями и физиологическим состоянием. Обычно сеанс длится 60 минут.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Ощущения</h3>
                                <p>Во время прессотерапии вы можете почувствовать ощущение приятного массажа или ритмичного сжатия-расжатия манжет. Многие люди описывают это ощущение как расслабляющее и комфортное.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Завершение сеанса</h3>
                                <p>После завершения сеанса манжеты медленно опустошаются, и вы можете снять их. </p>
                            </div>

                            <div className="tabs-item">
                                <h3>Рекомендации</h3>
                                <p>Эстетист по телу может дать вам рекомендации по поводу того, как часто следует проводить прессотерапию и какие результаты ожидать.</p>
                            </div>

                            <p>Важно помнить, что опыт прессотерапии может варьироваться в зависимости от конкретного аппарата, специалиста и целей, поэтому всегда лучше уточнять детали перед началом процедуры.</p>

                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <p>
                                Важно помнить, что опыт прессотерапии может варьироваться в зависимости от конкретного аппарата, специалиста и целей, поэтому всегда лучше уточнять детали перед началом процедуры.
                            </p>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение циркуляции:</strong> Процедура стимулирует кровообращение, что может привести к улучшению питания тканей кислородом и питательными веществами.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение отеков:</strong> Прессотерапия помогает улучшить лимфодренаж, что может снизить отеки и поддержать баланс жидкости в организме.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Снижение целлюлита:</strong> Путем стимуляции микроциркуляции и лимфодренажа прессотерапия может помочь уменьшить видимость целлюлита.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Расслабление мышц:</strong> Ритмичные сжатия и расширения манжет могут снизить напряжение и расслабить мышцы.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение тонуса кожи:</strong> Прессотерапия может способствовать повышению упругости и тонуса кожи.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение общего состояния:</strong> Многие клиенты ощущают общее ощущение расслабления и свежесть после сеанса прессотерапии.</p>
                            </div>

                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Помощь в реабилитации:</strong> Прессотерапия может помочь восстановлению после травм, хирургических вмешательств или интенсивных физических нагрузок.</p>
                            </div>

                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Стимуляция обмена веществ:</strong> Улучшение циркуляции и лимфодренажа может поддерживать более активный обмен веществ.</p>
                            </div>

                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Поддержание здорового веса:</strong> Процедура может помочь поддерживать баланс жидкости в организме, что может влиять на общее состояние и вес.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение настроения:</strong> Расслабление и улучшение ощущения своего тела могут способствовать повышению настроения.</p>
                            </div>
                            <p>
                                Важно понимать, что результаты могут различаться у разных людей, и они могут быть более заметны при регулярных сеансах. Перед началом прессотерапии рекомендуется проконсультироваться с медицинским специалистом, чтобы понять, насколько эта процедура подходит вам и какие результаты можно ожидать.
                            </p>
                        </div>

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div class="tabs-item">
                                <div class="beforeafter_container">
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>До</p>
                                    </div>
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </section>

            <section className='services-section'>
            <div className='faq-container'>
                            <div>
                                <h3>Часто задаваемые вопросы</h3>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-1">Что такое прессотерапия? <img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-1' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Прессотерапия - это процедура, включающая последовательное сжатие и расширение манжет, приложенных к различным частям тела, для стимуляции кровообращения и лимфодренажа. Может включать подогрев.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-2">Каковы преимущества прессотерапии?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-2' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-2' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Прессотерапия может помочь снять отеки, улучшить кровообращение, уменьшить целлюлит, облегчить усталость и напряжение в мышцах.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-3">Сколько длится сеанс прессотерапии?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-3' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-3' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Продолжительность сеанса обычно составляет 60 минут.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-4">Сколько сеансов требуется для видимых результатов?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-4' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-4' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Количество сеансов может варьироваться в зависимости от целей и индивидуальных потребностей человека. Обычно рекомендуется серия из 5-10 сеансов для достижения наилучших результатов.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-5">Какие части тела могут быть подвергнуты прессотерапии?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-5' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-5' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Прессотерапия может применяться для ног, рук, бедер, живота и других областей, в зависимости от того, какие проблемы нужно решить.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-6">Есть ли противопоказания к прессотерапии?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-6' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-6' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Да, есть определенные медицинские состояния и заболевания, при которых прессотерапия может быть нежелательной или противопоказанной. Рекомендуется консультация с врачом перед началом процедуры.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-7">Что ощущает пациент во время прессотерапии?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-7' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-7' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Во время прессотерапии пациент может почувствовать ощущение ритмичного сжатия и расширения манжет, похожее на массаж. Многие люди описывают это ощущение как приятное и расслабляющее.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-8">Можно ли комбинировать прессотерапию с другими процедурами?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-8' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-8' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Да, в некоторых случаях прессотерапия может быть частью комплексного подхода к уходу за телом, включая массаж, обертывания и другие процедуры.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-9">Какую одежду следует носить на процедуру прессотерапии?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-9' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                                <div class="panel" style={faqTab === 'faq-9' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Вам предоставят специальную одежду для использования во время процедуры. </p>
                                </div>
                            </div>


                        </div>
            </section>

            <section className='services-section'>
            <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>Обертывание</h3>
                    <a href='/Covering' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

                <div className='relevant-item'>
                    <h3>Сферотерапия</h3>
                    <a href='/Spheretherapy' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

                <div className='relevant-item'>
                    <h3>EMSCulpt</h3>
                    <a href='/EMSCULPT' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

            </section>
            <Footer />
        </>

    );
};


export default Presstherapy;