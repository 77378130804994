import logo from './logo.svg';
import './App.css';
import NavigationDesk from './Components/NavigationDesk';
import MainBanner from './Components/MainBanner';
import About from './Components/About';
import Usp from './Components/Usp';
import Catalog from './Components/Catalog/Catalog';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EMSCULPT from './Services/EMSCULPT';
import Liposonix from './Services/Liposonix';
import Main from './Components/Main';
import LPG from './Services/LPG';
import RFLifting from './Services/RFLifting';
import Cryolipoliz from './Services/Cryolipoliz';
import Presstherapy from './Services/Presstherapy';
import Cavitation from './Services/Cavitation';
import Covering from './Services/Covering';
import LaserEpil from './Services/LaserEpil';
import Massage from './Services/Massage';
import Spheretherapy from './Services/Spheretherapy';
import Abonement from './Services/Abonement';

function App() {
  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   // Функция для получения данных из внешнего источника
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('http://anvilis.ru/cryon/TESTS/get_list_of_categories.php');
  //       const data = await response.json();
  //       setCategories(data); // Обновление состояния с полученными данными
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData(); // Вызов функции для получения данных при монтировании компонента
  // }, []); // Пустой массив зависимостей гарантирует, что эффект выполняется только один раз при монтировании компонента

 


  return (
    <div className="App">
      {/* Your existing code
      <NavigationDesk />
      <MainBanner /> 
      <About />
      <Usp />
      <hr className="border-gradient"/>
      <Catalog categories={categories}/> */}
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/EMSCULPT" element={<EMSCULPT />} />
          <Route path="/Liposonix" element={<Liposonix />} />
          <Route path="/LPG" element={<LPG />} />
          <Route path="/RFLifting" element={<RFLifting />} />
          <Route path="/Cryolipoliz" element={<Cryolipoliz />} />
          <Route path="/Presstherapy" element={<Presstherapy />} />
          <Route path="/Cavitation" element={<Cavitation />} />
          <Route path="/Covering" element={<Covering />} />
          <Route path="/LaserEpil" element={<LaserEpil />} />
          <Route path="/Massage" element={<Massage />} />
          <Route path="/Spheretherapy" element={<Spheretherapy/>} />
          <Route path="/Abonement" element={<Abonement />} />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
