import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const RFLifting = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section">
                <h1 className="service-h1">RF-лифтинг</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/cryo-rf-cavitation.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p><strong>RF-лифтинг (радиочастотный лифтинг)</strong> - это процедура, использующая радиочастотные волны для стимуляции коллагена и улучшения упругости кожи. Она направлена на уменьшение морщин, подтяжку кожи и общее омоложение. RF-лифтинг может проводиться на лице и других областях тела. Во время процедуры аппарат генерирует радиочастотные волны, которые нагревают глубокие слои кожи. Это стимулирует производство коллагена и улучшает кровообращение. В результате кожа становится более упругой, подтянутой, а морщины могут уменьшиться. RF-лифтинг считается неинвазивной и безболезненной процедурой. Он может быть хорошей альтернативой хирургическим методам омоложения, таким как пластическая хирургия.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/a7c41foP_x4" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <ul>

                        <p>RF-лифтинг имеет ряд преимуществ, которые делают его популярным методом для улучшения упругости кожи и борьбы с морщинами.</p>
                        <li className="tabs-item">
                            <h3>Неинвазивность</h3>
                            <p>RF-лифтинг не требует хирургического вмешательства, инъекций или нарушения целостности кожи. Это безопасный и не травматичный метод омоложения.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Минимальное восстановление</h3>
                            <p>После процедуры обычно нет длительного периода восстановления. Пациенты могут вернуться к повседневной деятельности сразу после сеанса.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Улучшение упругости</h3>
                            <p>RF-лифтинг способствует стимуляции производства коллагена и эластина в глубоких слоях кожи. Это улучшает её упругость и тонус.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Сглаживание морщин</h3>
                            <p>Процедура помогает сгладить морщины, включая мелкие и глубокие.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Подтяжка контуров</h3>
                            <p>RF-лифтинг способен улучшить контуры лица и тела, что делает его эффективным для подтяжки обвисшей кожи.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Улучшение текстуры кожи</h3>
                            <p>Клиенты часто отмечают, что после процедуры кожа становится более гладкой и однородной.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Минимальные побочные эффекты</h3>
                            <p>В сравнении с инвазивными методами омоложения, побочные эффекты RF-лифтинга обычно незначительны. Может возникнуть временное покраснение или небольшой дискомфорт, который быстро проходит.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Подходит для разных областей</h3>
                            <p>Если вы ищете метод с минимальным временем восстановления после процедуры, криолиполиз может быть хорошим вариантом.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Нежелание хирургического вмешательства</h3>
                            <p>Криолиполиз предоставляет возможность уменьшить жировые отложения без необходимости хирургической операции.</p>
                        </li>
                        <p>Важно помнить, что криолиполиз не является методом для снижения общего веса. Он предназначен для коррекции конкретных зон и уменьшения жировых отложений в определенных областях тела. Перед принятием решения о проведении процедуры рекомендуется консультация с медицинским специалистом.</p>
                    </ul>
                </div>

                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>

                    <ul>
                        <p>RF-лифтинг может быть рекомендован для ряда косметических проблем и ситуаций. Вот некоторые из показаний для проведения процедуры RF-лифтинга:</p>
                        <li className="tabs-item">
                            <h3>Утрата упругости и тонуса кожи</h3>
                            <p>Если ваша кожа стала менее упругой из-за старения, факторов окружающей среды или других факторов, RF-лифтинг может помочь восстановить упругость.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Морщины и складки:</h3>
                            <p>Процедура может использоваться для сглаживания морщин, включая мимические морщины, морщины вокруг глаз (краешков глаз), морщины на лбу и другие.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Подвисшая кожа</h3>
                            <p>Если у вас есть проблемы с обвисшей кожей, особенно на областях лица, шеи, декольте, живота или бедер, RF-лифтинг может помочь подтянуть контуры.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Портретная подтяжка</h3>
                            <p></p>
                        </li>

                        <li className="tabs-item">
                            <h3>Разглаживание рубцов и стрий</h3>
                            <p>В некоторых случаях RF-лифтинг может помочь улучшить видимость рубцов и стрий, делая их менее заметными.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Обработка тела</h3>
                            <p>Процедура может использоваться для подтяжки кожи на теле, также как и на лице, например, на бедрах, ягодицах или руках.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Постакне и улучшение текстуры кожи</h3>
                            <p>RF-лифтинг может помочь улучшить текстуру кожи, сократить размер пор и справиться с следами постакне.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Поддержание результата после других процедур</h3>
                            <p>RF-лифтинг может быть дополнительной процедурой для поддержания результатов после инъекций или других процедур омоложения.</p>
                        </li>

                    </ul>

                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>

                    <ul>
                        <p>
                            Процедура RF-лифтинга может быть не подходящей для некоторых людей из-за определенных медицинских состояний или других факторов. Вот некоторые противопоказания к проведению RF-лифтинга:
                        </p>

                        <li className="tabs-item">
                            <h3>Беременность и грудное вскармливание</h3>
                            <p>Во избежание потенциального воздействия на развивающийся плод или ребенка, процедура не рекомендуется для беременных и кормящих женщин.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Кожные инфекции или воспаления</h3>
                            <p>Активные кожные инфекции, герпес, акне или другие воспалительные состояния могут быть противопоказанием к проведению процедуры.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Открытые раны или поврежденная кожа</h3>
                            <p>Процедура не должна проводиться на областях с открытыми ранами, ссадинами или другими повреждениями кожи.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Противопоказания к теплу</h3>
                            <p>Людям с противопоказаниями к высоким температурам может быть не рекомендован RF-лифтинг, так как он включает использование тепла для стимуляции тканей.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Сосудистые расстройства</h3>
                            <p>Людям с сосудистыми расстройствами, включая сосудистую дистонию, сосудистую купероз и другие состояния, может потребоваться дополнительная осторожность.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Иммунодепрессии</h3>
                            <p>Людям с ослабленной иммунной системой, например, в результате иммуносупрессивной терапии, могут потребоваться дополнительные меры предосторожности.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Импланты или металлические устройства</h3>
                            <p>Присутствие металлических имплантов, стентов или других металлических устройств в области процедуры может быть противопоказанием.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Эпилепсия</h3>
                            <p>Процедура, включающая воздействие радиочастотными волнами, может быть противопоказана людям с эпилепсией.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Рак и онкологические заболевания</h3>
                            <p>Людям с активным раком, обострением онкологического заболевания или прошедшими химиотерапию/лучевую терапию может потребоваться воздержание от RF-лифтинга.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Другие медицинские состояния</h3>
                            <p>Люди с хроническими заболеваниями, такими как сердечные проблемы, диабет, гипертония и другими, должны обсудить свои состояния со специалистом перед проведением процедуры.</p>
                        </li>

                        <p>Это не полный список противопоказаний, и они могут различаться в зависимости от медицинского состояния человека. Перед принятием решения о проведении криолиполиза рекомендуется провести консультацию с медицинским специалистом.</p>

                    </ul>
                </div>

                <p className='recommendation'>RF-Lifting: Подарите вашей коже молодость без операции! Радиочастотные волны вернут упругость, сгладят морщины и подтянут контуры лица и тела.</p>


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/rf.jpg" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <p>
                                Процедура RF-лифтинга проходит следующим образом:
                            </p>
                            <div className="tabs-item">
                                <h3>Подготовка</h3>
                                <p>На начальном этапе вы проводите консультацию с эстетистом по телу.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Очищение</h3>
                                <p>Процедура начинается с очищения кожи в области, которую планируется обрабатывать. </p>
                            </div>

                            <div className="tabs-item">
                                <h3>Нанесение геля</h3>
                                <p>На кожу наносится специальный гель, который помогает улучшить проводимость радиочастотных волн и повысить эффективность процедуры.</p>
                            </div>


                            <div className="tabs-item">
                                <h3>Манипула</h3>
                                <p>Эстетист по телу начинает прикладывать манипулу на обрабатываемую область. Манипула генерирует радиочастотные волны, которые проникают в глубокие слои кожи.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Тепло</h3>
                                <p>Радиочастотные волны нагревают ткани под кожей. Этот тепловой эффект стимулирует производство коллагена, что способствует упругости и подтяжке кожи.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Ощущения</h3>
                                <p>Вы можете почувствовать легкое тепло и покалывание в обрабатываемой области. Некоторые клиенты описывают это ощущение как комфортное и расслабляющее.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Контроль</h3>
                                <p>Процедура проводится с учетом индивидуальных особенностей вашей кожи и ощущений. Специалист контролирует интенсивность и глубину воздействия.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Завершение</h3>
                                <p>По завершении процедуры гель удаляется, а на кожу может быть нанесен успокаивающий крем.</p>
                            </div>



                            <p>Важно помнить, что результаты криолиполиза будут проявляться со временем, поскольку разрушенные жировые клетки постепенно выводятся из организма. Могут потребоваться повторные сессии для достижения наилучших результатов. Процедура проводится под контролем эститиста по телу.</p>

                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <p>
                                Результаты процедуры RF-лифтинга могут быть заметны через несколько недель после проведения сеансов. Эффекты могут варьироваться в зависимости от индивидуальных особенностей кожи и числа проведенных процедур.
                            </p>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>	Подтянутая и упругая кожа</strong> Одним из главных результатов RF-лифтинга является подтянутость и улучшение упругости кожи. </p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Сглаживание морщин:</strong> Процедура помогает сгладить морщины, делая их менее выраженными. Это может включать мимические морщины, морщины на лбу, вокруг глаз и другие.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение текстуры:</strong> Кожа может стать более гладкой и однородной. Это особенно актуально для тех, у кого есть текстурные неровности или следы после акне.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Общий вид кожи:</strong> RF-лифтинг способствует стимуляции производства коллагена, что может придать коже более молодой и свежий вид.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Подтяжка контуров:</strong> Области, которые подвергаются RF-лифтингу, могут иметь более четкие и подтянутые контуры.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение объемов:</strong> В некоторых случаях процедура может способствовать незначительному уменьшению объемов.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Длительность результатов:</strong> Результаты RF-лифтинга могут длиться от нескольких месяцев до полугода и более, в зависимости от множества факторов. Для поддержания долгосрочных результатов иногда рекомендуется проводить периодические обследования.</p>
                            </div>

                        </div>

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div class="tabs-item">
                                <div class="beforeafter_container">
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>До</p>
                                    </div>
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </section>
            <secition className='services-section'>
            <div className='faq-container'>
                            <div>
                                <h3>Часто задаваемые вопросы</h3>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-1">Что такое RF-лифтинг?</button>
                                <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                                    <p>RF-лифтинг - это процедура, использующая радиочастотные волны для стимуляции производства коллагена и подтяжки кожи без хирургического вмешательства.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-2">Какие области могут быть обработаны с помощью RF-лифтинга?</button>
                                <div class="panel" style={faqTab === 'faq-2' ? { display: 'block' } : { display: 'none' }}>
                                    <p>RF-лифтинг может использоваться на лице, шее, декольте, животе, бедрах и других областях тела.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-3">Болезненна ли процедура?</button>
                                <div class="panel" style={faqTab === 'faq-3' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Процедура обычно не вызывает сильной боли. Многие клиенты ощущают только легкое тепло и покалывание.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-4">Сколько сеансов требуется для видимых результатов?</button>
                                <div class="panel" style={faqTab === 'faq-4' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Результаты могут появиться после нескольких сеансов, но для достижения наилучших результатов может потребоваться серия процедур.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-5">Каково время восстановления после процедуры?</button>
                                <div class="panel" style={faqTab === 'faq-5' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Восстановление обычно минимально. Пациенты могут вернуться к повседневной деятельности практически сразу после сеанса.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-6">Как долго будут держаться результаты?</button>
                                <div class="panel" style={faqTab === 'faq-6' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Результаты могут длиться от нескольких месяцев до полугода и более, в зависимости от индивидуальных факторов.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-7">Существуют ли противопоказания?</button>
                                <div class="panel" style={faqTab === 'faq-7' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Да, есть определенные медицинские состояния и обстоятельства, при которых RF-лифтинг не рекомендуется. Обязательно проконсультируйтесь со специалистом.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-8">Какие ощущения я буду испытывать во время процедуры?</button>
                                <div class="panel" style={faqTab === 'faq-8' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Ощущения могут включать легкое тепло и покалывание, которые обычно описывают как комфортные.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-9">Когда можно ожидать видимых результатов?</button>
                                <div class="panel" style={faqTab === 'faq-9' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Результаты обычно начинают появляться через несколько недель после сеансов, поскольку процесс образования нового коллагена требует времени.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-10">Сколько времени длится каждый сеанс?</button>
                                <div class="panel" style={faqTab === 'faq-10' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Продолжительность сеанса может варьироваться, обычно она составляет от 20 до 30 минут.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-11">Может ли RF-лифтинг заменить хирургический лифтинг?</button>
                                <div class="panel" style={faqTab === 'faq-11' ? { display: 'block' } : { display: 'none' }}>
                                    <p>RF-лифтинг может быть хорошей альтернативой хирургическим методам омоложения, но результаты могут быть менее радикальными и временными.</p>
                                </div>
                            </div>


                        </div>
            </secition>
            <section className='services-section'>
            <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>LPG массаж</h3>
                    <a href='/LPG' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

                <div className='relevant-item'>
                    <h3>Сферотерапия</h3>
                    <a href='/Spheretherapy' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

            </section>
            <Footer />
        </>

    );
};


export default RFLifting;