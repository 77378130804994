import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Cavitation = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section">

                <h1 className="service-h1">Кавитация</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/cryo-rf-cavitation.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p><strong>Кавитация</strong> - это быстрый и эффективный способ избавиться от нежелательных жировых отложений и улучшить форму вашего тела. </p>
                            <p>Кавитация подразумевает воздействие ультразвуковых волн низкой частоты на кожу и подкожные слои. Аппарат для кавитации устроены таким образом, что создают колебательные движения, которые влияют на количество жировых клеток, превращая их в газообразную смесь. При помощи ультразвука под кожей образуются небольшие пузырьки, клеточная мембрана разрушается. Кавитационные микропузырьки лопаются, словно воздушные шарики, жир превращается в жидкость при этом и выходит из организма. В механизме активно задействованы чудесные свойства печени и естественный метаболический процесс. Усиление эффекта достигается лимфодренажным массажем.</p>
                            <p>Одно из важных свойств кавитации медицина уже безоговорочно признала: во время процедуры не происходит негативного воздействия на ткани, мышцы и сосуды.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/Gs0xMyQG_AI" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <ul>
                        <p>Процедура кавитации предоставляет ряд значительных преимуществ:</p>

                        <li className="tabs-item">
                            <h3>Уменьшение объемов тела</h3>
                            <p>Кавитация помогает снизить объемы тела, что делает ее эффективным методом для моделирования силуэта и борьбы с нежелательными жировыми отложениями.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Устранение целлюлита</h3>
                            <p>Кавитация может существенно улучшить вид и текстуру кожи, уменьшая целлюлит и делая кожу более гладкой.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Неинвазивность</h3>
                            <p>Она не требует хирургического вмешательства, инъекций или резких воздействий на кожу, что означает минимальные риски и период восстановления.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Безболезненность</h3>
                            <p>Процедура кавитации обычно не связана с болевыми ощущениями. Клиенты могут ощущать лишь легкое тепло или приятное массажное действие.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Скорость и эффективность</h3>
                            <p>Видимые результаты часто заметны уже после первой процедуры, что делает ее привлекательной для тех, кто хочет быстро улучшить свою фигуру.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Индивидуальный подход</h3>
                            <p>Процедура может быть настроена на конкретные потребности клиента и области тела, которые требуют обработки.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Безопасность</h3>
                            <p>Кавитация, проводимая опытными специалистами, считается безопасной и малорисковой процедурой.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Отсутствие ограничений в повседневной жизни</h3>
                            <p>После процедуры клиенты могут вернуться к обычной активности, не испытывая каких-либо ограничений или болезненных ощущений.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Сокращение времени и усилий</h3>
                            <p>Кавитация предоставляет альтернативу длительным диетам и интенсивным тренировкам для достижения желаемых результатов.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Поддержание результатов</h3>
                            <p>После завершения основного курса процедур, поддержание результатов может быть достигнуто с помощью здорового образа жизни и регулярного физического активности.</p>
                        </li>




                        <p className='warning'>Важно помнить, что индивидуальные результаты могут варьировать в зависимости от начального состояния пациента, соблюдения рекомендаций по послепроцедурному уходу и выполнения рекомендаций специалиста.</p>
                    </ul>

                </div>

                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                    <ul>
                        <p>Процедура кавитации может быть рекомендована в следующих случаях и для следующих показаний:</p>

                        <li className="tabs-item">
                            <h3>Нежелательные жиры</h3>
                            <p>Если у вас есть участки нежелательных жировых отложений, такие как "жирные складки" на животе, бедрах, ягодицах или руках, кавитация может помочь снизить объемы в этих зонах.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Моделирование фигуры</h3>
                            <p>Кавитация может использоваться для моделирования силуэта, чтобы создать более стройный и пропорциональный облик.</p>
                        </li>


                        <li className="tabs-item">
                            <h3>Целлюлит</h3>
                            <p>Кавитация эффективно снижает видимость целлюлита, поэтому она может быть рекомендована тем, кто хочет улучшить текстуру кожи.</p>
                        </li>


                        <li className="tabs-item">
                            <h3>Подготовка к особым событиям</h3>
                            <p>Многие люди обращаются к кавитации, когда им нужно быстро подготовиться к особым событиям, таким как свадьба, отпуск или важное мероприятие.</p>
                        </li>


                        <li className="tabs-item">
                            <h3>Улучшение самочувствия</h3>
                            <p>Некоторые люди выбирают кавитацию для повышения уверенности в себе и общего самочувствия.</p>
                        </li>


                        <li className="tabs-item">
                            <h3>Необходимость неинвазивных методов</h3>
                            <p>Кавитация предоставляет альтернативу хирургическим методам уменьшения объемов тела и липосакции, что привлекательно для тех, кто хочет избежать операций.</p>
                        </li>


                        <li className="tabs-item">
                            <h3>Дополнение к диете и упражнениям</h3>
                            <p>Кавитацию можно использовать как дополнение к здоровому образу жизни, включая правильное питание и регулярные физические упражнения.</p>
                        </li>




                        <p>Важно отметить, что у процедуры есть противопоказания и ограничения, такие как беременность, наличие металлических имплантатов в области обработки, серьезные медицинские состояния и другие факторы, которые могут влиять на безопасность и эффективность процедуры. Поэтому важно проконсультроваться с врачем перед процедурой.</p>
                    </ul>
                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                    <ul>
                        <p>Процедура кавитации может быть не рекомендована или даже опасной в следующих случаях: </p>

                        <li className="tabs-item">
                            <h3>Беременность</h3>
                            <p>Кавитация не рекомендуется для беременных женщин из-за возможных негативных воздействий на организм и развивающегося плода.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Лактация</h3>
                            <p>Если вы кормите грудью, то также лучше избегать кавитации из-за неопределенных воздействий на молоко и молочные железы.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Противопоказания к ультразвуковым воздействиям</h3>
                            <p>Кавитация использует ультразвук, поэтому противопоказания к ультразвуковым процедурам могут также относиться к кавитации. Например, если у вас есть кожные инфекции, открытые раны, воспаления, облученная кожа или опухоли в области обработки, кавитация может быть недопустима.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Сердечные заболевания и проблемы с кровообращением</h3>
                            <p>Людям с серьезными сердечными заболеваниями, аритмией или проблемами с кровообращением рекомендуется избегать кавитации.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Противопоказания к массажу и манипуляциям</h3>
                            <p>Если у вас есть нарушения сосудов, сосудистые заболевания, тромбофлебит, тромбозы или проблемы с кровопотоком, то кавитация может быть опасной.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Желчнокаменная болезнь</h3>
                            <p>Кавитация может вызвать боли или обострение желчнокаменной болезни.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Ожирение III степени</h3>
                            <p>У людей с ожирением III степени может не быть видимых результатов от кавитации из-за больших объемов жировых отложений.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Эпилепсия</h3>
                            <p>По некоторым данным, ультразвуковое воздействие может вызвать приступы у людей с эпилепсией.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Металлические имплантаты</h3>
                            <p>Наличие металлических имплантатов в области обработки может быть противопоказанием, так как металл может отразить ультразвук и вызвать нежелательные эффекты.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Дети и подростки</h3>
                            <p>Процедура не подходит для детей и подростков из-за недостаточно исследований о воздействии на их растущие организмы.</p>
                        </li>



                        <p>Помните, что это общие противопоказания, и конкретные рекомендации могут варьироваться в зависимости от состояния вашего здоровья. Поэтому важно проконсультроваться с врачем перед процедурой.</p>
                    </ul>
                </div>


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt-2.png" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <div className="tabs-item">
                                <h3>Подготовка</h3>
                                <p>Эстетист проводит первичную консультацию, во время которой расскажет про процедуру и о ее преимуществах и ожидаемых результатах. Вы также можете обсудить свои цели и области тела, которые требуют обработки.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Подготовка кожи</h3>
                                <p>Область, которую вы хотите обработать, будет очищена от масел и косметики, после чего будет нанесен гель для проведения процедуры.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Процедура</h3>
                                <p>Эстетист использует специальный аппарат, который генерирует ультразвуковые волны. Эти волны применяются к области тела, которую вы хотите обработать. Ультразвуковые волны создают циклические изменения давления, что вызывает кавитацию, то есть образование и коллапсирование мельчайших пузырьков внутри жировых клеток.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Массаж и лимфодренаж</h3>
                                <p>После завершения кавитации, некоторые специалисты могут провести массаж или лимфодренаж в области обработки. Это может помочь улучшить циркуляцию крови и удалить разрушенные жировые клетки.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Завершение процедуры</h3>
                                <p>После завершения процедуры вы можете вернуться к повседневным делам без необходимости периода восстановления.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Рекомендации по уходу</h3>
                                <p>Вам могут дать рекомендации по послепроцедурному уходу, такие как пить больше воды, избегать жирных продуктов и алкоголя, а также поддерживать активный образ жизни.</p>
                            </div>



                            <p>Обычно сеанс кавитации занимает от 20 до 30 минут в зависимости от области обработки и индивидуальных потребностей. Для достижения наилучших результатов, часто рекомендуется проводить серию процедур с интервалами между ними, как правило, не менее чем через несколько дней.</p>

                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <p>
                                Результаты проведения процедуры кавитации могут варьировать в зависимости от индивидуальных особенностей клиента, начального состояния кожи и тела. Результаты кавитации могут включать в себя следующее:
                            </p>


                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение объемов тела:</strong> Кавитация помогает уменьшить объемы в области обработки, что может быть видимо уже после первых нескольких сеансов.</p>
                            </div>

                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение текстуры кожи:</strong> Процедура способствует улучшению общей текстуры кожи, делая ее более гладкой и упругой.</p>
                            </div>

                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Борьба с целлюлитом:</strong> Кавитация может помочь уменьшить видимость целлюлита, особенно в областях, где он является проблемой.</p>
                            </div>

                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Моделирование силуэта:</strong> Проведение кавитации в сочетании с правильным подходом к диете и физической активностью может помочь вам достичь желаемой формы и силуэта.</p>
                            </div>

                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшенное кровообращение:</strong> Процедура может стимулировать кровообращение в области обработки, что способствует обогащению тканей кислородом и питательными веществами.</p>
                            </div>

                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Поддержание результатов:</strong> Для поддержания достигнутых результатов важно следовать здоровому образу жизни, включая сбалансированное питание и физическую активность.</p>
                            </div>

                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Снижение уровня стресса и повышение уверенности:</strong> Улучшение внешности и самочувствия может повысить уровень уверенности в себе и уменьшить стресс.</p>
                            </div>

                            <p>
                                Важно отметить, что результаты могут быть наиболее заметными после проведения полного курса процедур, а не только после одной сессии. Индивидуальный опыт также может различаться, поэтому важно иметь реалистичные ожидания и проконсультироваться с врачем перед процедурой, чтобы определить подойдет ли вам процедура кавитации для достижения желаемых результатов.
                            </p>
                        </div>

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div class="tabs-item">
                                <div class="beforeafter_container">
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>До</p>
                                    </div>
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>

            <section className='services-section'>
                <div className='faq-container'>
                    <div>
                        <h3>Часто задаваемые вопросы</h3>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-1">Что такое кавитация в косметологии?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-1' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                            <p>Кавитация в косметологии - это процедура, при которой ультразвуковые волны используются для разрушения жировых клеток в подкожном слое. Это помогает уменьшить объемы тела, улучшить текстуру кожи и бороться с целлюлитом.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-2">Каковы основные преимущества кавитации?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-2' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-2' ? { display: 'block' } : { display: 'none' }}>
                            <p>Основные преимущества кавитации включают в себя уменьшение объемов тела, борьбу с целлюлитом, улучшение текстуры кожи, отсутствие необходимости в хирургическом вмешательстве и небольшой период восстановления.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-3">Бывают ли какие-либо побочные эффекты от кавитации?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-3' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-3' ? { display: 'block' } : { display: 'none' }}>
                            <p>Обычно кавитация считается безопасной и малорисковой процедурой, но могут возникнуть временные побочные эффекты, такие как покраснение, легкое онемение или незначительные синяки в области обработки. Эти эффекты обычно исчезают в течение нескольких дней.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-4">Сколько сеансов кавитации требуется для достижения результатов?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-4' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-4' ? { display: 'block' } : { display: 'none' }}>
                            <p>Для достижения наилучших результатов обычно рекомендуется провести серию процедур, обычно от 5 до 10 сеансов, с интервалами в несколько дней. Однако индивидуальные рекомендации могут различаться в зависимости от целей и начального состояния клиента.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-5">Какой уход требуется после процедуры кавитации?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-5' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-5' ? { display: 'block' } : { display: 'none' }}>
                            <p>После процедуры рекомендуется увлажнять кожу, избегать высококалорийной пищи и алкоголя, а также поддерживать активный образ жизни. Также следует избегать сильного солнечного воздействия на обработанную область.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-6">Могу ли я вернуться к обычным делам после кавитации?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-6' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-6' ? { display: 'block' } : { display: 'none' }}>
                            <p>Да, после кавитации выможете немедленно вернуться к своим повседневным делам, так как процедура не требует периода восстановления.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-7">Есть ли какие-либо противопоказания для кавитации?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-7' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-7' ? { display: 'block' } : { display: 'none' }}>
                            <p>Да, существуют противопоказания, такие как беременность, лактация, проблемы с сосудами, сердечные заболевания и другие медицинские состояния. Важно проконсультироваться с врачом перед проведением процедуры.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-8">Когда я увижу результаты после кавитации?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-8' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-8' ? { display: 'block' } : { display: 'none' }}>
                            <p>Некоторые результаты могут быть заметны уже после первой процедуры, но наилучшие результаты обычно достигаются после завершения полного курса процедур.</p>
                        </div>
                    </div>


                </div>
            </section>

            <section className='services-section'>
                <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>LPG массаж</h3>
                    <a href='/LPG' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>Кавитация</h3>
                    <a href='/Cavitation' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
            </section>
            <Footer />
        </>

    );
};


export default Cavitation;