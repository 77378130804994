import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const LPG = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
        console.log(e.target.id);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section padding-for-main-banner">

                <h1 className="service-h1">LPG-массаж</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/LPG.jpg" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p>LPG-массаж (LPG Endermologie, лпиджи массаж, лпг массаж, липомассаж) – это инновационная процедура, направленная на улучшение состояния кожи, лимфодренаж, снижение объемов тела и борьбу с целлюлитом. Во время LPG-массажа используется специальное аппаратное оборудование, которое выполняет мягкое вакуумное воздействие на кожу, совмещая массаж и механический роликовый массаж.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/KDz-MFq6i74" title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <ul>
                        <li className="tabs-item">
                            <p><strong>Уменьшение целлюлита. </strong>
                                LPG-массаж способствует разрушению жировых клеток, улучшая циркуляцию крови и лимфы. Это может значительно уменьшить видимость целлюлита на коже.</p>
                        </li>
                        <li className="tabs-item">
                            <p><strong>Улучшение контуров тела. </strong>
                                Процедура помогает уменьшить объемы тела, что может привести к более подтянутой и упругой коже. Это особенно актуально для тех, кто стремится корректировать определенные зоны тела.</p>
                        </li>
                        <li className="tabs-item">
                            <p><strong>Стимуляция обмена веществ. </strong>
                                LPG-массаж улучшает микроциркуляцию и обмен веществ в тканях, что может способствовать более эффективному сжиганию жиров и выведению токсинов.</p>
                        </li>
                        <li className="tabs-item">
                            <p><strong>Повышение упругости кожи. </strong>
                                Процедура стимулирует синтез коллагена и эластина, важных белков для упругости и эластичности кожи. Это может сделать кожу более гладкой и молодой.</p>
                        </li>
                        <li className="tabs-item">
                            <p><strong>Лимфодренаж. </strong>
                                Механические движения LPG-аппарата помогают улучшить лимфодренаж – процесс выведения лишней жидкости и токсинов из организма. Это может уменьшить отечность и улучшить состояние кожи.</p>
                        </li>
                        <li className="tabs-item">
                            <p><strong>Расслабление и оздоровление. </strong>
                                Многие люди отмечают ощущение расслабления и комфорта во время процедуры. LPG-массаж также может помочь улучшить сон и общее психоэмоциональное состояние.</p>
                        </li>
                        <li className="tabs-item">
                            <p><strong>Неинвазивность. </strong>
                                Многие клиенты замечают видимые результаты через несколько недель после процедуры. Одна процедура дает возможность носить одежду на один размер меньше.</p>
                        </li>
                        <li className="tabs-item">
                            <p><strong>Индивидуальный подход. </strong>
                                Параметры LPG-аппарата могут быть настроены в соответствии с потребностями каждого человека, позволяя достичь наилучших результатов для конкретных проблемных зон.</p>
                        </li>
                    </ul>
                </div>


                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                    <p className='recommendation'>
                        LPG-массаж может быть эффективным решением для ряда эстетических проблем. Вот некоторые показания для проведения LPG-массажа:
                    </p>
                <ul>
                    <li className="tabs-item">
                        <p><strong>Целлюлит и несовершенства кожи. </strong>
                        LPG-массаж часто используется для уменьшения видимости целлюлита и улучшения текстуры кожи, делая ее более гладкой и упругой.</p>
                    </li>
                    <li className="tabs-item">
                        <p><strong>Объемы тела. </strong>
                        Если у вас есть проблемные зоны с избыточными объемами (например, бедра, живот, ягодицы), LPG-массаж может помочь уменьшить эти объемы и придать более подтянутый вид.</p>
                    </li>
                    <li className="tabs-item">
                        <p><strong>Посттравматическое восстановление. </strong>
                        LPG-массаж может помочь восстановлению после травм, операций или родовых растяжений, способствуя уменьшению отеков, улучшению циркуляции и восстановлению тканей.</p>
                    </li>
                    <li className="tabs-item">
                        <p><strong>Улучшение обмена веществ. </strong>
                        Процедура может стимулировать обмен веществ, помогая организму более эффективно сжигать жиры и выделять токсины.</p>
                    </li>
                    <li className="tabs-item">
                        <p><strong>Улучшение упругости кожи. </strong>
                        LPG-массаж стимулирует синтез коллагена и эластина, что может улучшить упругость и эластичность кожи.</p>
                    </li>
                    <li className="tabs-item">
                        <p><strong>Лимфатические проблемы. </strong>
                        Процедура может помочь при лимфатической застойности, улучшая лимфодренаж и уменьшая отечность.</p>
                    </li>
                    <li className="tabs-item">
                        <p><strong>Подготовка косметических процедур. </strong>
                        LPG-массаж иногда используется в качестве подготовительной процедуры перед другими эстетическими вмешательствами, чтобы улучшить результаты.</p>
                    </li>
                    <li className="tabs-item">
                        <p><strong>Стресс и напряжение. </strong>
                        Массажные процедуры, включая LPG-массаж, могут помочь снять стресс, улучшить настроение и общее состояние организма.</p>
                    </li>

                    </ul>
                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                    <p className='warning'>
                        LPG-массаж имеет определенные противопоказания, при которых проведение процедуры может быть нежелательным или даже опасным. Перед началом LPG-массажа важно проконсультироваться с медицинским специалистом, чтобы убедиться, что процедура безопасна для вас. Вот некоторые общие противопоказания:
                    </p>

                    <ul>

                    <li className="tabs-item">
                        <p><strong>Онкологические заболевания. </strong>
                        LPG-массаж не рекомендуется для людей с активными онкологическими процессами, так как массаж может способствовать распространению опухолей.</p>
                    </li>

                    <li className="tabs-item">
                        <p><strong>Тромбоз и тромбофлебит. </strong>
                        Людям с нарушением свертываемости крови, тромбозами или тромбофлебитами не рекомендуется проводить LPG-массаж, так как массаж может усилить риск образования сгустков крови.</p>
                    </li>

                    <li className="tabs-item">
                        <p><strong>Заболевания кожи. </strong>
                        Некоторые кожные заболевания, такие как открытые раны, инфекции, дерматиты и псориаз, могут стать противопоказаниями к проведению LPG-массажа.</p>
                    </li>

                    <li className="tabs-item">
                        <p><strong>Сосудистые заболевания. </strong>
                        Людям с серьезными сосудистыми заболеваниями, такими как варикозное расширение вен, следует избегать LPG-массажа, так как он может усилить сосудистые проблемы.</p>
                    </li>

                    <li className="tabs-item">
                        <p><strong>Беременность. </strong>
                        Во время беременности проведение LPG-массажа может быть нежелательным, особенно в первом триместре. Важно проконсультироваться с врачом, прежде чем решать о проведении процедуры.</p>
                    </li>

                    <li className="tabs-item">
                        <p><strong>Сердечно-сосудистые заболевания. </strong>
                        У людей с серьезными заболеваниями сердца или высоким артериальным давлением может быть ограничение на проведение массажа.</p>
                    </li>

                    <li className="tabs-item">
                        <p><strong>Заболевания нервной системы. </strong>
                        Некоторые заболевания нервной системы, такие как невропатии или эпилепсия, могут сделать массаж нежелательным.</p>
                    </li>

                    <li className="tabs-item">
                        <p><strong>Индивидуальные особенности. </strong>
                        Индивидуальные особенности каждого человека могут также влиять на возможность проведения LPG-массажа. Например, аллергии, чувствительность к боли и другие факторы могут потребовать ограничений.</p>
                    </li>
                    </ul>
                    <p className='warning'>При выборе LPG-массажа важно проконсультироваться с квалифицированным специалистом и убедиться, что процедура подходит для ваших индивидуальных целей и состояния здоровья.</p>
                </div>

                <p className='recommendation'>Преобразуйте свою кожу с LPG массажем: упругость, гладкость и контур без хирургии!</p>


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/LPG-how-to.jpg" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <p>
                                Процедура LPG-массажа проводится с использованием специального аппарата, который имеет два ролика и вакуумный насос. Вот общий ход процедуры LPG-массажа:
                            </p>
                            <ul>
                                <li className="tabs-item">
                                    <p><strong>Подготовка. </strong>
                                    Перед началом процедуры специалист проводит консультацию, чтобы определить цели и пожелания, а также оценить состояние кожи.</p>
                                </li>

                                <li className="tabs-item">
                                    <p><strong>Очистка кожи. </strong>
                                    Перед началом массажа кожу обычно очищают от макияжа и загрязнений, чтобы обеспечить наилучший контакт массажных роликов с кожей.</p>
                                </li>

                                <li className="tabs-item">
                                    <p><strong>Специальный костюм. </strong>
                                    Для проведения процедуры LPG-массажа для тела – необходим специальный костюм, для плавного и безболезненного скольжения роликов по телу.</p>
                                </li>

                                <li className="tabs-item">
                                    <p><strong>Процедура массажа. </strong>
                                    Специалист начинает проводить роликами по коже с помощью LPG-аппарата. Ролики прокатываются вдоль тела, оказывая воздействие на кожу и подкожные слои. Вакуумный насос создает легкое всасывание и расслабление кожи, а ролики массируют ткани. </p>
                                </li>

                                <li className="tabs-item">
                                    <p><strong>Пошаговое движение. </strong>
                                    Процедура проводится по определенным линиям и зонам, начиная с легких движений и постепенно увеличивая интенсивность массажа.</p>
                                </li>

                                <li className="tabs-item">
                                    <p><strong>Специализированные движения. </strong>
                                    Во время процедуры специалист может использовать разнообразные техники и движения, направленные на конкретные зоны и проблемы, например, придавливание, скольжение, растягивание и т.д.</p>
                                </li>

                                <li className="tabs-item">
                                    <p><strong>Коррекция интенсивности. </strong>
                                    Интенсивность массажа может быть настроена в зависимости от индивидуальных ощущений и реакции кожи на процедуру.</p>
                                </li>

                                <li className="tabs-item">
                                    <p><strong>Продолжительность сеанса. </strong>
                                    Длительность сеанса может варьироваться, обычно она составляет от 20 (лицо) до 45 (тело) минут.</p>
                                </li>

                                <li className="tabs-item">
                                    <p><strong>Рекомендации по уходу. </strong>
                                    После процедуры специалист может дать рекомендации по уходу за кожей и дополнительным процедурам, если необходимо.</p>
                                </li>
                            </ul>

                            <p className='recommendation'>Чтобы достичь наилучших результатов, обычно рекомендуется проводить LPG-массаж в виде курсов, состоящих из нескольких сеансов, с определенным интервалом между ними.</p>

                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <p>
                                Результаты после курса LPG-массажа могут быть разнообразными и зависят от индивидуальных особенностей человека, начального состояния кожи и целей, которые были поставлены. Вот некоторые из возможных результатов, которые можно ожидать после завершения курса LPG-массажа:
                            </p>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение целлюлита:</strong> Один из основных эффектов LPG-массажа – это уменьшение целлюлита и более гладкая кожа.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение объемов:</strong> Курс LPG-массажа может помочь уменьшить объемы в проблемных зонах, таких как бедра, живот и ягодицы.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Повышение упругости кожи:</strong> Заслуживает отдельного упоминания – улучшение упругости и эластичности кожи. Массаж стимулирует производство коллагена и эластина, что сделает кожу более подтянутой.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение текстуры кожи:</strong> Кожа становится более гладкой и мягкой, а также может уменьшиться видимость рубцов и растяжек.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Лимфодренаж и микроциркуляция:</strong> LPG-массаж стимулирует лимфодренаж и микроциркуляцию, что способствует выведению токсинов из организма и улучшению обмена веществ.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Общее ощущение комфорта и расслабления: </strong>Многие отмечают ощущение расслабления и улучшение настроения после прохождения курса LPG-массажа.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Заметные результаты внешне:</strong> После завершения курса LPG-массажа можно ожидать более выраженных контуров тела, менее выраженного целлюлита и более упругую кожу.</p>
                            </div>

                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Длительность результатов:</strong> Результаты могут сохраняться в течение определенного времени после завершения курса, но для поддержания долгосрочных эффектов могут потребоваться дополнительные процедуры.</p>
                            </div>
                            <p className='warning'>
                                Важно понимать, что результаты могут варьировать в зависимости от индивидуальных особенностей организма. Чтобы достичь наилучших результатов, рекомендуется соблюдать здоровый образ жизни, включая сбалансированное питание и регулярную физическую активность.
                            </p>
                        </div>

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div className="tabs-item">
                                <div className="beforeafter_container">
                                    <div className="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>До</p>
                                    </div>
                                    <div className="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
            <section className='faq-section'>
                <div className='faq-container'>
                    <div>
                        <h2>Часто задаваемые вопросы</h2>
                    </div>
                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-1"><span>Что такое LPG-массаж?</span> <img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-1' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                            <p>LPG-массаж (LPG Endermologie, лпиджи массаж, лпг массаж, липомассаж) - это процедура, основанная на вакуумном массаже с роликами, которая направлена на уменьшение целлюлита, улучшение текстуры кожи и поддержание общего состояния кожи и тканей.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-2"><span>Каков механизм действия LPG-массажа?</span><img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-2' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-2' ? { display: 'block' } : { display: 'none' }}>
                            <p>LPG-массаж использует механические движения роликов и вакуумный насос для стимуляции кровообращения, лимфодренажа и выработки коллагена и эластина, что помогает улучшить упругость и состояние кожи.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-3"><span>Сколько сеансов LPG-массажа требуется для достижения результатов?</span><img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-3' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-3' ? { display: 'block' } : { display: 'none' }}>
                            <p>Количество сеансов может варьировать в зависимости от начального состояния кожи и целей клиента. Обычно рекомендуется курс из 5-10 сеансов с интервалом в несколько дней.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-4"><span>Какова продолжительность одного сеанса?</span><img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-4' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-4' ? { display: 'block' } : { display: 'none' }}>
                            <p>Продолжительность сеанса обычно составляет от 20 (лицо) до 45 (тело) минут, в зависимости от зон, которые подвергаются обработке.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-5">Какие зоны тела можно подвергнуть LPG-массажу?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-5' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-5' ? { display: 'block' } : { display: 'none' }}>
                            <p>LPG-массаж может применяться на различных зонах тела, таких как бедра, живот, ягодицы, руки и спина. Это зависит от потребностей клиента. Также существует отдельная манипула для лица.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-6">Бывают ли побочные эффекты LPG-массажа?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-6' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-6' ? { display: 'block' } : { display: 'none' }}>
                            <p>В редких случаях после массажа может возникнуть временная покраснение кожи или легкое недомогание. Однако побочные эффекты обычно минимальны.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-7">Существуют ли противопоказания для LPG-массажа?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-7' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-7' ? { display: 'block' } : { display: 'none' }}>
                            <p>Да, есть противопоказания, такие как онкологические заболевания, тромбоз, сердечные заболевания, беременность и другие. Перед началом процедуры важно проконсультироваться с врачом.</p>
                        </div>
                    </div>
                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-8">Какие результаты можно ожидать после LPG-массажа?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-8' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-8' ? { display: 'block' } : { display: 'none' }}>
                            <p>После курса LPG-массажа можно ожидать уменьшения целлюлита, улучшения текстуры кожи, повышения упругости и уменьшения объемов в проблемных зонах.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-9">Как часто рекомендуется проводить LPG-массаж для поддержания результатов?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-9' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-9' ? { display: 'block' } : { display: 'none' }}>
                            <p>Для поддержания результатов может потребоваться периодическое повторение процедур. Частота зависит от индивидуальных потребностей и целей.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button className="accordion" onClick={activateFaqTab} id="faq-10">Может ли LPG-массаж заменить физические упражнения и/или диету?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-10' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                        <div className="panel" style={faqTab === 'faq-10' ? { display: 'block' } : { display: 'none' }}>
                            <p>LPG-массаж не может полностью заменить физическую активность и правильное питание. Это дополнительная процедура, которая может помочь достичь желаемых результатов.</p>
                        </div>
                    </div>


                </div>
            </section>
            <section className='services-section'>
                
                <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>EMSCULPT</h3>
                    <a href='/EMSCULPT' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>RF-лифтинг</h3>
                    <a href='/RFLifting' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>Сферотерапия</h3>
                    <a href='/Spheretherapy' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                

            </section>

            <Footer />
        </>

    );
};


export default LPG;