import React from 'react';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Covering = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
        <NavigationDesk />
        <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section">
            
                <h1 className="service-h1">Обертывание</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p><strong>Липолитическое обертывание</strong> – это косметическая процедура, направленная на уменьшение объема и улучшение внешнего вида кожи</p>
                            <p>Эти процедуры способны оказать мощное оздоравливающее и корректирующее воздействие на организм: есть обертывание для похудения, питания кожи, борьбы с целлюлитом и т.д.</p>
                            <p>Один из действенных и простых способов для избавления от избыточного веса и «апельсиновой корочки» — это антицеллюлитное обертывание в салоне красоты. Выводит из кожи токсические вещества и продукты метаболизма, устраняет шелушение и шероховатость кожи, а также способствует ее увлажнению.</p>
                            <p>Обертывания для борьбы с целлюлитом и улучшения качества кожи это самая популярная процедура во всем мире.  Кожа выравнивается, напитывается, выглядит более гладкой. Признаки целлюлита становятся менее заметны . Отмечается общая подтянутость и тонус кожи. Вы сможете заметить целый ряд изменений, которые с вами произойдут: уменьшение размера жировых клеток, насыщение кожи минеральными и питательными веществами, возобновление ее упругости, эластичности, молодости и нежности.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/v7RYqpPXCNU" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    {/* <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
            <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button> */}
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    
                    <ul>
                    <p>Вот некоторые из преимуществ липолитического обертывания:</p>



                    <li className="tabs-item">
                        <h3>Уменьшение объема</h3>
                        <p>Липолитические обертывания помогают уменьшить объем жировых отложений, что особенно актуально для проблемных зон, таких как бедра, живот, ягодицы и верхняя часть рук.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Улучшение текстуры кожи</h3>
                        <p>Значительно улучшает текстуру и внешний вид кожи: повышает упругость тканей, облагораживает цвет кожи, делает ее гладкой.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Увлажнение кожи</h3>
                        <p>Многие липолитические обертывания включают в себя глубоко увлажняющие компоненты, что способствует общему улучшению состоянию кожи.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Расслабление и омоложение</h3>
                        <p>Процедура помогает расслабиться (особенно в сочетании с прессотерапией) и принесет ощущение ухода за собой, что может быть приятным опытом.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Минимизация целлюлита</h3>
                        <p>Липолитические обертывания часто применяются для уменьшения видимости целлюлита на коже.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Неинвазивность</h3>
                        <p>Липолитические обертывания не требуют хирургического вмешательства.</p>
                    </li>


                    <p>Важно понимать, что результаты липолитического обертывания могут варьироваться, и зависят от многих факторов, таких как индивидуальные характеристики, образ жизни и количества проведенных процедур. Перед проведением липолитического обертывания важно проконсультироваться со своим врачем и обсудить свои ожидания.</p>

                    </ul>      
                </div>

                {/* <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                                
                <div className="tabs-item">
                    <h3></h3>
                    <p></p>
                </div>
              

                <p>Важно отметить, что у процедуры есть противопоказания и ограничения, такие как беременность, наличие металлических имплантатов в области обработки, серьезные медицинские состояния и другие факторы, которые могут влиять на безопасность и эффективность процедуры. Поэтому важно проконсультроваться с врачем перед процедурой.</p>
               
            </div> */}

                {/* <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                            
                <div className="tabs-item">
                    <h3></h3>
                    <p></p>
                </div>

            </div> */}


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt-2.png" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            {/* <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                    <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button> */}
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <div className="tabs-item">
                                <h3></h3>
                                <p></p>
                            </div>


                        </div>

                        {/* <div id="result"  className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                    
                        <div className="tabs-item results">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path
                                d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                fill="#E23DB4" />
                            </svg></div>
                            <p></p>
                        </div>
                        
                    </div> */}

                        {/* <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                        <div class="tabs-item">
                            <div class="beforeafter_container">
                            <div class="beforeafter_images">
                                <img src="./img/services-images/beforeafter-1.png" />
                                <p>До</p>
                            </div>
                            <div class="beforeafter_images">
                                <img src="./img/services-images/beforeafter-1.png" />
                                <p>После</p>
                            </div>
                            </div>
                        </div>
                    </div> */}

                        <div className='faq-container'>
                            <div>
                                <h3>Часто задаваемые вопросы</h3>
                            </div>

                            <button class="accordion" onClick={activateFaqTab} id="faq-1">Что такое кавитация в прессотерапия?</button>
                            <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                                <p>Прессотерапия - это процедура, включающая последовательное сжатие и расширение манжет, приложенных к различным частям тела, для стимуляции кровообращения и лимфодренажа. Может включать подогрев.я с целлюлитом.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='services-section'>
            <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>Прессотерапия</h3>
                    <a href='/Presstherapy' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>LPG массаж</h3>
                    <a href='/LPG' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
            </section>
            <Footer />
        </>

    );
};


export default Covering;