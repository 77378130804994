import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Liposonix = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section addMargin">
                <h1 className="service-h1">Липосоникс</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/liposonix.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p>Липосоникс (Liposonix) – это инновационный аппарат для неинвазивного разрушения нежелательных жировых отложений и моделирования фигуры с помощью технологии высокоинтенсивного сфокусированного ультразвука (HIFU) за одну процедуру.</p>
                            <p>Липосоникс обеспечивает локальное, точечное воздействие на проблемные участки тела. Волны ультразвука, помимо разрушения жировой ткани, вызывают сокращение волокон коллагена — с уменьшением объемов вы получаете эффект лифтинга. Выраженный эффект виден уже после одной процедуры.</p>
                            <p>Liposonix действует только на слой жировой ткани на глубине 1-2 см, на большей глубине ультразвук расфокусируется и никак не влияет на внутренние органы.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/ERcOOiBzzwY" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <ul>
                        <p className='recommendation'>Процедура Liposonix имеет ряд преимуществ, которые делают ее привлекательной для тех, кто ищет неинвазивные методы уменьшения жира. Вот некоторые из главных преимуществ Liposonix:</p>
                        <li className="tabs-item">
                            <h3>Неинвазивность</h3>
                            <p>Liposonix не требует нижних операций, игл или анестезии. Это означает, что пациенты могут избежать хирургических рисков и долгих периодов восстановления.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Отсутствие осложнений</h3>
                            <p>Поскольку процедура не включает в себя ножевое вмешательство или уколы, риск инфекций, рубцов и других осложнений минимизирован.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Местное уменьшение жира</h3>
                            <p>Liposonix позволяет точечно обрабатывать конкретные области, где жир является проблемой. Это дает возможность добиваться местного уменьшения жировых отложений.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Долгосрочные результаты</h3>
                            <p>После разрушения жировых клеток, они постепенно выводятся из организма, что приводит к долгосрочному уменьшению объема в области процедуры.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Индивидуальный подход</h3>
                            <p>Процедура может быть настроена в соответствии с индивидуальными целями и потребностями клиента.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Безболезненность</h3>
                            <p>Во время процедуры могут быть ощущения тепла и легкие сокращения мышц, но обычно она не сопровождается болевыми ощущениями.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Эффективность</h3>
                            <p>Многие клиенты замечают видимые результаты через несколько недель после процедуры. Одна процедура дает возможность носить одежду на один размер меньше.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Безопасность</h3>
                            <p>Liposonix основана на технологии высокочастотного ультразвука, которая давно используется в медицинских целях и имеет высокий уровень безопасности. Разрушение жировой ткани происходит за счет нагрева, а не за счет кавитации: отсутствуют непрогнозируемые эффекты, нет побочного влияния на внутренние органы.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Некоторое улучшение тонуса кожи</h3>
                            <p>В процессе разрушения жировых клеток, происходит стимуляция коллагена, что может привести к некоторому улучшению тонуса кожи в области процедуры.</p>
                        </li>
                    </ul>
                </div>

                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                    <ul>
                        <p className='recommendation'>
                            Липосоникс обычно рекомендуется для людей, у которых есть жировые отложения в определенных областях тела, которые трудно уменьшить с помощью диеты и физической активности. Идеально подходит для коррекции местных жировых отложений — тех мест, где толщина подкожного жирового слоя составляет не меньше 2 сантиметров. Вот некоторые общие показания для процедуры Liposonix:
                        </p>

                        <li className="tabs-item">
                            <h3>Жировые отложения</h3>
                            <p>Липосоникс часто рекомендуется тем, кто имеет умеренные жировые отложения в определенных областях тела, таких как живот, бедра, ягодицы, бока или верхняя часть рук.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Тонкая коррекция</h3>
                            <p>Процедура подходит для пациентов, которые находятся в хорошей общей физической форме, но хотят устранить упорные жировые отложения, которые остаются даже после тренировок и соблюдения диеты.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Целевые области</h3>
                            <p>Липосоникс можно применять для уменьшения объема в конкретных областях, которые могут быть проблемными с точки зрения внешнего вида.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Реалистичные ожидания</h3>
                            <p>Липосоникс наилучшим образом подходит для тех, кто имеет реалистичные ожидания относительно результатов и понимает, что процедура может помочь уменьшить объем, но не заменяет здоровый образ жизни.</p>
                        </li>
                        <li className="tabs-item">
                            <h3>Дополнительная обработка после других методов</h3>
                            <p>Некоторые клиенты выбирают Liposonix в качестве дополнительной процедуры после хирургических методов уменьшения жира для дополнительного улучшения результатов.</p>
                        </li>

                        <p className='warning'>
                            Прежде чем пройти процедуру Липосоникс (Liposonix), рекомендуется провести консультацию с врачем, который оценит ваши медицинские и косметические потребности, чтобы убедиться, что процедура вам подходит.
                        </p>
                    </ul>
                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                    <ul>
                        <p className='warning'>
                            Процедура Липосоникс (Liposonix) имеет некоторые противопоказания, при которых она может быть нежелательной или небезопасной. Перед проведением процедуры важно проконсультироваться с врачем.  Вот некоторые из общих противопоказаний:
                        </p>

                        <li className="tabs-item">
                            <h3>Беременность и грудное вскармливание</h3>
                            <p>Процедура не рекомендуется беременным женщинам или тем, кто кормит грудью, так как воздействие ультразвука на организм может иметь непредсказуемые последствия для плода или грудного молока.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Опухоли и опухолеподобные образования</h3>
                            <p>Процедура не рекомендуется людям с опухолями, опухолеподобными образованиями или раком в области, которая подлежит обработке.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Заболевания кожи</h3>
                            <p>Наличие активных воспалений, инфекций, рубцов и других заболеваний кожи в области процедуры может быть противопоказанием.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Грыжи</h3>
                        </li>

                        <li className="tabs-item">
                            <h3>Кишечные и мочевыводящие нарушения</h3>
                            <p>Процедура может быть нежелательной для людей с активными заболеваниями кишечника, почек или мочевыводящих путей.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Сердечно-сосудистые заболевания</h3>
                            <p>Пациенты с сердечно-сосудистыми заболеваниями, такими как аритмии, сердечная недостаточность или пороки сердца, могут подвергаться риску от воздействия ультразвука.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Импланты</h3>
                            <p>Наличие металлических имплантов, стентов или других медицинских устройств в области процедуры может быть противопоказанием.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Нарушения свертываемости крови</h3>
                            <p>Процедура может быть нежелательной для людей с нарушениями свертываемости крови, в том числе  в том числе в результате приема антикоагулянтов.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Активные инфекции</h3>
                            <p>Наличие активных инфекций в организме также может быть противопоказанием.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Ожоги и повреждения кожи</h3>
                            <p>Процедура не рекомендуется на поврежденной коже или областях с ожогами.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Другие медицинские состояния</h3>
                            <p>Пациенты с определенными медицинскими состояниями, такими как сахарный диабет, аутоиммунные, хронические заболевания и другие, должны быть осторожны при рассмотрении Liposonix.</p>
                        </li>

                        <li className="tabs-item">
                            <h3>Хронические заболевания </h3>
                            <p>в стадии обострения</p>
                        </li>

                    </ul>
                </div>

                <p className='recommendation'>Липосоникс: формируйте фигуру без операции – ультразвуковая технология для точечного разрушения жировых отложений.</p>


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/liposonix-how-to.jpg" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <p>
                                Перед проведением процедуры рекомендуется проконсультироваться с врачем. Вот общий обзор того, как проходит процедура Liposonix:
                            </p>
                            <div className="tabs-item">
                                <h3>Первый этап</h3>
                                <p>Консультация и осмотр с эстетистом по телу. Определяется проблемная область и наличие жировой складки не менее 2 см. </p>
                            </div>

                            <div className="tabs-item">
                                <h3>Позиционирование</h3>
                                <p>Вы будете размещены на кушетке или кресле в зависимости от области тела, которую планируется обработать. Далее эстетист отмечает специальным маркером участки, где планируется процедура. </p>
                            </div>

                            <div className="tabs-item">
                                <h3>Тонкая настройка аппарата</h3>
                                <p>Следующим этапом является выбор индивидуального режима интенсивности в зависимости от порога чувствительности.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Проведение процедуры</h3>
                                <p>Эстетист прикладывает манипулу к первому участку и процедура начинается. Это продолжается до тех пор, пока все участки не будут обработаны необходимым образом. </p>
                            </div>

                            <div className="tabs-item">
                                <h3>Сокращение жировых клеток</h3>
                                <p>Во время обработки вы можете почувствовать тепло и некоторое сокращение мышц. Это связано с действием ультразвука на жировые клетки.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Регулировка параметров</h3>
                                <p>Специалист может регулировать интенсивность и глубину ультразвука в зависимости от ваших ощущений и индивидуальных характеристик.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Время проведения процедуры</h3>
                                <p>Обычно обработка живота и боков занимает около получаса – зависит от выбранного количества квадратов. Время может варьироваться в зависимости от зоны воздействия и индивидуальных особенностей. Процедура проводится эстетистом в условиях обычного косметологического кабинета, не требует специальной подготовки и последующего наблюдения. </p>
                            </div>

                            <p className='advice'>После процедуры вы можете вернуться к своим повседневным делам, но рекомендуется избегать интенсивных физических нагрузок в течение нескольких дней. Рекомендуется провести несколько сеансов с определенными интервалами для достижения оптимальных результатов.</p>

                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <p>
                                Результаты процедуры Liposonix могут быть видны через несколько недель после проведения сеанса, но они будут продолжать улучшаться в течение нескольких месяцев по мере выведения разрушенных жировых клеток из организма. Важно понимать, что результаты могут варьироваться в зависимости от индивидуальных характеристик и области, которая подверглась обработке. Вот что можно ожидать от результатов процедуры Liposonix:
                            </p>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение объема:</strong> Одним из основных результатов Liposonix является уменьшение объема в области, подвергнутой процедуре. Это может привести к более подтянутому и утонченному внешнему виду.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Местное уменьшение жира:</strong> Процедура наиболее эффективна для уменьшения жира в конкретных областях, где его сложно убрать с помощью диеты и физических упражнений.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение контуров тела:</strong> Результаты Liposonix могут помочь улучшить контуры тела, придавая ему более гармоничный и симметричный вид.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Постепенное улучшение:</strong> Большинство людей видят максимальный результат в течение 8—12 недель. Этот срок соответствует времени, которое необходимо организму, чтобы естественным образом метаболизировать разрушенную жировую ткань.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение тонуса кожи:</strong> В процессе разрушения жировых клеток Liposonix также стимулирует синтез коллагена, что может привести к некоторому улучшению тонуса кожи в области процедуры.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Долгосрочные результаты:</strong> Атакуемые жировые клетки разрушаются навсегда, однако окружающие жировые клетки могут продолжать расти, поэтому наилучший способ сохранить полученный результат — это поддерживать здоровый образ жизни.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Индивидуальность результатов:</strong> Результаты могут отличаться у разных людей, по данным клинических исследований среднее уменьшение объема талии составляет один размер (до 5 сантиметров).</p>
                            </div>
                            <i>Перед проведением процедуры рекомендуется проконсультироваться с врачем.</i>
                        </div>

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div class="tabs-item">
                                <div class="beforeafter_container">
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>До</p>
                                    </div>
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <section className='services-section'>
                <div className='faq-container'>
                    <div>
                        <h3>Часто задаваемые вопросы</h3>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-1">Каковы ощущения во время процедуры?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-1' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                            <p>Во время процедуры вы можете испытывать незначительный дискомфорт, ощущение тепла внутри кожи и холода на поверхности. После процедуры неприятные ощущения исчезают.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-2">Сколько времени займет процедура?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-2' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-2' ? { display: 'block' } : { display: 'none' }}>
                            <p>Процедура обычно длится около 30 минут, в зависимости от размера области, которую обрабатывают.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-3">Каких результатов мне ожидать?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-3' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-3' ? { display: 'block' } : { display: 'none' }}>
                            <p>В среднем вы потеряете столько лишнего жира, что сможете надеть одежду на 1 размер меньше. В талии это будет минус до 5 сантиметров.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-4">Как быстро я увижу результат?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-4' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-4' ? { display: 'block' } : { display: 'none' }}>
                            <p>Большинство людей видят максимальный результат через 8-12 недель после процедуры. Именно столько времени нужно, чтобы организм естественным образом удалил разрушенные жировые клетки. </p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-5">Сколько сеансов потребуется?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-5' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-5' ? { display: 'block' } : { display: 'none' }}>
                            <p>Оптимальное количество сеансов может различаться в зависимости от индивидуальных целей и области обработки. Обычно рекомендуется сеты из 6-12 процедур. </p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-6">Есть ли побочные эффекты?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-6' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-6' ? { display: 'block' } : { display: 'none' }}>
                            <p>Некоторые пациенты могут испытывать временное покраснение, небольшую опухоль или покалывание в области обработки, но это обычно проходит само по себе.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-7">Как долго результаты будут держаться?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-7' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-7' ? { display: 'block' } : { display: 'none' }}>
                            <p>Результаты могут сохраняться долгое время, при условии поддержания здорового образа жизни.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-8">Насколько безопасна процедура Liposonix?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-8' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-8' ? { display: 'block' } : { display: 'none' }}>
                            <p>Действие Liposonix на организм тщательно исследовалось на протяжении более 10 лет, за это время не было выявлено каких либо серьезных осложнений.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-9">Что мне нужно делать после процедуры?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-9' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-9' ? { display: 'block' } : { display: 'none' }}>
                            <p>Вам нет необходимости делать что-то дополнительно, просто ожидайте результата. Врачи рекомендуют продолжать сохранять режим питания и диеты, которые были у Вас до процедуры.</p>
                        </div>
                    </div>

                    <div className='accordionContainer'>
                        <button class="accordion" onClick={activateFaqTab} id="faq-10">Сколько квадратов мне необходимо?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-10' ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} /></button>
                        <div class="panel" style={faqTab === 'faq-10' ? { display: 'block' } : { display: 'none' }}>
                            <p>Среднее количество квадратов по зонам:</p>
                            <ul>
                                <li>Руки 4-8 кв.</li>
                                <li>Бока 4-12 кв.</li>
                                <li>Живот 4-12 кв. (стандарт 9 кв.)</li>
                                <li>Бедро внутренняя часть 4-8 кв. (2 ноги)</li>
                                <li>Бедро наружная часть 4-12 кв. (2 ноги)</li>
                                <li>Над коленями 2-4 кв. (2 ноги)</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className='services-section'>
                <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>RF-Лифтинг</h3>
                    <a href='/RFLifting' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>LPG массаж</h3>
                    <a href='/LPG' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

            </section>
            <Footer />
        </>

    );
};


export default Liposonix;