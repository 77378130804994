import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Cryolipoliz = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section">
                <h1 className="service-h1">Криолиполиз</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/cryo-rf-cavitation.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p><strong>Криолиполиз</strong> – новейшая безоперационная методика удаления локальных жировых отложений, основанная на контролируемом охлаждении подкожно-жировой ткани. Все дело в том, что жировые клетки, в отличие от клеток кожи, неустойчивы к холоду. И при охлаждении запускается программируемый процесс их гибели. Нежелательные объемы в области живота, спины, бедер, ягодиц, на боках в прямом смысле слова «исчезают»!</p>
                            <p>Процедура криолиполиз для похудения позволяет за одно посещение уменьшить количество жировой ткани в зоне коррекции на 20-30%, а после курса из 2-3 сеансов уменьшить поверхностный жир в проблемных областях до 80%</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/UJmHLLmwI2M" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <ul>
                    
                    <li className="tabs-item">
                        <h3>Неинвазивность</h3>
                        <p>Криолиполиз является неинвазивной процедурой, то есть не требует хирургического вмешательства, ножа или инъекций. Отсутствие разрезов и ран снижает риск инфекций и осложнений.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Отсутствие времени восстановления</h3>
                        <p>После процедуры криолиполиза клиенты обычно могут вернуться к повседневным делам сразу после процедуры, без необходимости долгой реабилитации.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Минимальный дискомфорт</h3>
                        <p>При криолиполизе могут возникнуть небольшие ощущения холода и массажных движений, но процедура обычно считается более комфортной по сравнению с хирургическими методами.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Локализованное воздействие</h3>
                        <p>Криолиполиз позволяет точно нацеливать область тела, которая требует коррекции, минимизируя воздействие на окружающие ткани.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Естественное выведение жиров</h3>
                        <p>После разрушения жировых клеток они естественным образом выводятся из организма через лимфатическую и метаболическую системы.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Долгосрочные результаты</h3>
                        <p>Результаты криолиполиза могут сохраняться длительное время, особенно при соблюдении здорового образа жизни.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Безопасность</h3>
                        <p>Криолиполиз является одобренной и безопасной процедурой, исследования показали ее эффективность и отсутствие серьезных побочных эффектов.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Альтернатива хирургическим методам</h3>
                        <p>Для тех, кто не готов или не хочет подвергаться хирургическим вмешательствам, криолиполиз представляет собой неинвазивную альтернативу.</p>
                    </li>

                    </ul>

                </div>
                <p>Важно понимать, что результаты криолиполиза могут варьировать в зависимости от индивидуальных особенностей, начального состояния тканей и соблюдения рекомендаций специалистов. Перед решением о проведении процедуры рекомендуется проконсультироваться с врачем.</p>

                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                    <ul>
                    
                    <p>
                        Криолиполиз может быть рекомендован в следующих случаях:
                    </p>

                    <li className="tabs-item">
                        <h3>Участки с избыточными жировыми отложениями</h3>
                        <p>Криолиполиз эффективен для уменьшения жировых отложений в определенных зонах тела, таких как живот, бока, бедра, ягодицы, подбородок и верхняя часть рук.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Целлюлит и несовершенства кожи</h3>
                        <p>Процедура может помочь улучшить текстуру кожи и видимость целлюлита.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Поддержание результатов после потери веса</h3>
                        <p>Криолиполиз может быть использован для поддержания идеальных контуров тела после потери веса.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Формирование контуров тела</h3>
                        <p>Если у вас есть определенные проблемные зоны, которые мешают вам достичь желаемых контуров тела, криолиполиз может быть рекомендован.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Жиры, устойчивые к диете и упражнениям</h3>
                        <p>Даже при соблюдении здорового образа жизни некоторые жировые отложения могут оставаться устойчивыми. Криолиполиз может помочь устранить их.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Нежелательные объемы после беременности</h3>
                        <p>После беременности некоторые женщины могут столкнуться с трудностями в уменьшении объемов. Криолиполиз может помочь восстановить прежние формы.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Отсутствие времени на восстановление</h3>
                        <p>Если вы ищете метод с минимальным временем восстановления после процедуры, криолиполиз может быть хорошим вариантом.</p>
                    </li>
                    <li className="tabs-item">
                        <h3>Нежелание хирургического вмешательства</h3>
                        <p>Криолиполиз предоставляет возможность уменьшить жировые отложения без необходимости хирургической операции.</p>
                    </li>
                    <p>Важно помнить, что криолиполиз не является методом для снижения общего веса. Он предназначен для коррекции конкретных зон и уменьшения жировых отложений в определенных областях тела. Перед принятием решения о проведении процедуры рекомендуется консультация с медицинским специалистом.</p>
                    </ul>
                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                    <ul>
                    <p>
                        Криолиполиз может иметь противопоказания, при которых процедура может быть нежелательной или даже опасной. Перед решением о проведении криолиполиза важно проконсультироваться с медицинским специалистом. Вот некоторые общие противопоказания:
                    </p>

                    <li className="tabs-item">
                        <h3>Онкологические заболевания</h3>
                        <p>Процедура криолиполиза может быть нежелательной для пациентов с активными онкологическими заболеваниями или историей рака, так как массаж может способствовать распространению опухолей.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Заболевания кожи</h3>
                        <p>Криолиполиз не рекомендуется, если у вас есть кожные заболевания или наружные поражения в зоне, которая будет обрабатываться.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Лимфатические и сосудистые заболевания</h3>
                        <p>У пациентов с тромбозами, тромбофлебитами, варикозным расширением вен, лимфатической застойностью и другими сосудистыми проблемами может быть ограничение на проведение криолиполиза.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Беременность и грудное вскармливание</h3>
                        <p>Процедура не рекомендуется для беременных женщин и тех, кто кормит грудью.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Аллергии на холод</h3>
                        <p>Если у вас есть аллергическая реакция на холод или замороженные области кожи, криолиполиз может быть нежелательным.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Диабет</h3>
                        <p>У пациентов с диабетом может потребоваться дополнительное внимание и оценка врача перед проведением криолиполиза.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Заболевания нервной системы</h3>
                        <p>Некоторые заболевания нервной системы могут сделать криолиполиз недопустимым.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Ожоги или операционные рубцы</h3>
                        <p>Криолиполиз может негативно повлиять на области с ожогами или свежими операционными рубцами.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Имплантаты и металлические внутренние устройства</h3>
                        <p>Металлические имплантаты, стенты и другие металлические устройства могут быть противопоказанием.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Индивидуальные особенности</h3>
                        <p>Индивидуальные особенности человека могут также влиять на возможность проведения криолиполиза.</p>
                    </li>
                    <p>Это не полный список противопоказаний, и они могут различаться в зависимости от медицинского состояния человека. Перед принятием решения о проведении криолиполиза рекомендуется провести консультацию с медицинским специалистом.</p>
                

                <p className='recommendation'>Добейтесь желаемых контуров тела без хирургии или инъекций с помощью инновационной процедуры замораживания жировых клеток </p>
                </ul>
                </div>

            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/cryo-how-to.png" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <p>
                                Процедура криолиполиза обычно проходит следующим образом:
                            </p>
                            <div className="tabs-item">
                                <h3>Консультация</h3>
                                <p>Перед началом процедуры вы проводите консультацию с эстетистом по телу. На этом этапе вы обсуждаете ваши цели, ожидания и области тела, которые вы хотите обработать криолиполизом.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Оценка</h3>
                                <p>Эстетист оценивает ваши жировые отложения и анализирует области, которые могут быть обработаны. Он также обсудит с вами, сколько сессий может потребоваться для достижения желаемых результатов.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Фотографии или замеры</h3>
                                <p>В некоторых случаях могут быть сделаны фотографии и/или замеры до начала процедуры. Это поможет отследить изменения и сравнить результаты после процедуры.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Установка манипул</h3>
                                <p>Аппарат для криолиполиза снабжен манипулами, которые нацеливаются на выбранную область/ти. Перед установкой манипулы, эстетист по телу покрывает целевые области специальными одноразывыми сальфетками, которые пропитанны специальным гелем, который предотвращает переохлаждение.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Охлаждение</h3>
                                <p>По началу процедуры манипула/ы начинает/ют постепенно и контролируемо охлаждать целевые область/ти тела. Это может вызвать чувство холода и потянутости, но они обычно исчезают через некоторые время, когда кожа охлаждается.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Процесс обработки</h3>
                                <p>Обычно процедура длится около 30 (подбородок) 50 (тело) минут, в зависимости от размера и количества областей, которые обрабатываются.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Массаж и разогрев</h3>
                                <p>После завершения процедуры, эстетист снимает манипулы и массирует обработанные области.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Продолжительность сеанса</h3>
                                <p>Длительность сеанса может варьироваться, обычно она составляет от 20 (лицо) до 45 (тело) минут.</p>
                            </div>

                            <div className="tabs-item">
                                <h3>Завершение</h3>
                                <p>По завершении процедуры вы можете вернуться к повседневным делам без необходимости какой-либо реабилитации или восстановления.</p>
                            </div>

                            <p>Важно помнить, что результаты криолиполиза будут проявляться со временем, поскольку разрушенные жировые клетки постепенно выводятся из организма. Могут потребоваться повторные сессии для достижения наилучших результатов. Процедура проводится под контролем эститиста по телу.</p>

                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <p>
                                Результаты криолиполиза могут быть видны через несколько недель после процедуры, поскольку процесс выведения разрушенных жировых клеток из организма требует времени. Вот что вы можете ожидать после криолиполиза:
                            </p>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Уменьшение объемов:</strong> Один из основных результатов криолиполиза - это уменьшение объемов в областях, подвергшихся обработке. Обычно это видно через 1-2 месяца после процедуры. </p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение контуров: </strong>Криолиполиз помогает придать более гармоничные и симметричные контуры телу, уменьшая жировые отложения в проблемных зонах.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Улучшение упругости кожи: </strong>После криолиполиза происходит стимуляция выработки коллагена и улучшение упругости кожи в областях, которые подверглись процедуре.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Минимизация целлюлита: </strong>Криолиполиз также может помочь сгладить видимость целлюлита и сделать кожу более гладкой.</p>
                            </div>
                            <div className="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Постепенное выведение жиров: </strong>Разрушенные жировые клетки постепенно выводятся из организма через лимфатическую и метаболическую системы.</p>
                            </div>
                            <div class="tabs-item results">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                        fill="#E23DB4" />
                                </svg></div>
                                <p><strong>Долгосрочные результаты: </strong>После завершения курса криолиполиза результаты обычно сохраняются длительное время, особенно при поддержании здорового образа жизни.</p>
                            </div>

                            <p>
                                Важно понимать, что результаты могут варьировать в зависимости от индивидуальных особенностей каждого человека, начального состояния жировых отложений и даже выбранной области для обработки. Рекомендуется иметь реалистичные ожидания и проконсультроваться с врачем перед началом процедуры.
                            </p>
                        </div>

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div class="tabs-item">
                                <div class="beforeafter_container">
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>До</p>
                                    </div>
                                    <div class="beforeafter_images">
                                        <img src="./img/services-images/beforeafter-1.png" />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </section>
            <section className='services-section'>
            <div className='faq-container'>
                            <div>
                                <h3>Часто задаваемые вопросы</h3>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-1">Что такое криолиполиз?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-1' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Криолиполиз - это процедура неинвазивного удаления жировых отложений путем замораживания. Она основана на способности жировых клеток более чувствительно реагировать на низкие температуры.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-2">Как действует криолиполиз на жировые клетки?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-2' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-2' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Во время криолиполиза манипула контролируемо охлаждает область тела до -10 градусов. Это вызывает криоконденсацию жировых клеток, что постепенно приводит к их разрушению. Разрушенные клетки затем выводятся из организма естественным путем.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-3">Какие области тела могут быть обработаны криолиполизом?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-3' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-3' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Криолиполиз может быть применен к различным областям тела, включая живот, бока, бедра, ягодицы, спину, руки и подбородок.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-4">Сколько сеансов криолиполиза требуется для видимых результатов?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-4' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-4' ? { display: 'block' } : { display: 'none' }}>
                                    <p>В большинстве случаев рекомендуется проводить 1-3 сеанса для каждой области. Результаты становятся видимыми через несколько недель, и для достижения наилучших результатов может потребоваться несколько месяцев.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-5">Какие ощущения возникают во время процедуры?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-5' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-5' ? { display: 'block' } : { display: 'none' }}>
                                    <p>В начале процедуры вы можете ощутить холод и тянущие ощущения в обрабатываемой области. Затем эти ощущения притупляются. В целом процедура считается комфортной.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-6">Сколько времени требуется на одну сессию криолиполиза?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-6' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-6' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Продолжительность сеанса может варьироваться от 30 до 50 минут, в зависимости от области тела.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-7">Когда можно ожидать результаты после криолиполиза?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-7' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-7' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Первые результаты можно заметить через 2-3 недели после процедуры, но окончательные результаты становятся видимыми обычно через 2-4 месяца.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-8">Есть ли какие-либо побочные эффекты криолиполиза?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-8' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-8' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Обычно побочные эффекты могут включать временное покраснение, небольшую опухоль, онемение или чувство покалывания в обработанной области. Эти симптомы обычно проходят самостоятельно.</p>
                                </div>
                            </div>

                            <div className='accordionContainer'>
                                <button class="accordion" onClick={activateFaqTab} id="faq-9">Можно ли комбинировать криолиполиз с другими процедурами коррекции фигуры?<img src="./img/icons/faq-arrow.svg" style={faqTab === 'faq-9' ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)'}}/></button>
                                <div class="panel" style={faqTab === 'faq-9' ? { display: 'block' } : { display: 'none' }}>
                                    <p>Да, в зависимости от ваших целей и рекомендаций специалиста, криолиполиз можно комбинировать с другими процедурами, такими как LPG-массаж, физическими упражнениями и соблюдением здорового образа жизни.</p>
                                </div>
                            </div>


                        </div>
            </section>
            <section className='services-section'>
            <h2>Отлично сочетается:</h2>
                <div className='relevant-item'>
                    <h3>Прессотерапия</h3>
                    <a href='/Presstherapy' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>LPG массаж</h3>
                    <a href='/LPG' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>

            </section>
            <Footer />
        </>

    );
};


export default Cryolipoliz;