import React from 'react';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';

const LaserEpil = () => {

    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');
    const [faqTab, setFaqTab] = React.useState('faq1');

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (e) => {
        const clickedTab = e.target.id;
        setFaqTab(prevTab => prevTab === clickedTab ? '' : clickedTab);
    };

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section addMargin">
                <h1 className="service-h1">Лазерная эпиляция</h1>
                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt.png" />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p><strong>Лазерная эпиляция</strong> — самый быстрый и эффективный способ удаления ненужных волос навсегда. Избавление от растительности происходит с помощью лазерной установки: специалист задаёт мощность и длину волны в зависимости от эпилируемой зоны и личных особенностей клиента, и с помощью небольшой манипулы обрабатывает волосы. Сначала они становятся тоньше и бледнее, а затем перестают расти вовсе. </p>
                            <p>В каждой волосяной луковице (основании волоска) на нашем теле содержится меланин. Это пигмент, который даёт волосу цвет. У кого-то его меньше, волосы светлее. У кого-то пигмента много, волосы тёмные, заметные. Именно на этот пигмент и воздействует лазерный луч. Меланин притягивает световую энергию, накапливает её. Количество преобразуется в качество: световая энергия становится тепловой, фолликул нагревается, и как следствие - разрушается.</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/rQYvo7rIsfQ" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    {/* <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
            <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button> */}
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <ul>
                    <p><strong>Лазерная эпиляция</strong> имеет ряд преимуществ, которые делают этот метод удаления волос самым популярным и эффективным. Вот некоторые из главных преимуществ лазерной эпиляции:</p>



                    <li className="tabs-item">
                        <h3>Долгосрочные результаты</h3>
                        <p>Лазерная эпиляция может обеспечить долгосрочное уменьшение роста волос или даже полное их уничтожение. После нескольких сеансов волосы становятся более тонкими и редкими, и рост замедляется.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Скорость и эффективность</h3>
                        <p>Процедура лазерной эпиляции быстро обрабатывает большие участки кожи, что делает ее более эффективной и быстрой по сравнению с другими методами удаления волос, такими как восковая депиляция или использование эпилятора.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Меньше боли и дискомфорта</h3>
                        <p>Многие современные лазерные устройства оснащены системами охлаждения, которые уменьшают дискомфорт во время процедуры. Большинство пациентов описывают ее как меньше болезненную, чем другие методы удаления волос.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Меньше вероятности врастания волос</h3>
                        <p>Лазерная эпиляция помогает уменьшить вероятность врастания волос, что часто бывает проблемой при других методах.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Подходит для разных типов кожи и волос</h3>
                        <p>Современные лазерные системы предоставляют разные настройки, что позволяет их использовать на различных типах кожи и цветах волос. Однако, лазерная эпиляция может быть менее эффективной на светлых или седых волосах.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Меньше раздражения кожи</h3>
                        <p>В отличие от восковой депиляции, лазерная эпиляция не требует снятия волос вместе с корнем, что может уменьшить раздражение кожи.</p>
                    </li>

                    <li className="tabs-item">
                        <h3>Простота и удобство</h3>
                        <p>После нескольких сеансов лазерной эпиляции, пациентам часто не нужно беспокоиться о регулярном удалении волос в течение долгого времени.</p>
                    </li>

                    <p>Помимо этих преимуществ, важно отметить, что лазерная эпиляция требует серии сеансов для достижения оптимальных результатов, а эффективность может варьировать в зависимости от индивидуальных особенностей кожи. </p>

                    </ul>
                </div>

                {/* <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                                
                <div className="tabs-item">
                    <h3></h3>
                    <p></p>
                </div>
              

                <p>Важно отметить, что у процедуры есть противопоказания и ограничения, такие как беременность, наличие металлических имплантатов в области обработки, серьезные медицинские состояния и другие факторы, которые могут влиять на безопасность и эффективность процедуры. Поэтому важно проконсультроваться с врачем перед процедурой.</p>
               
            </div> */}

                {/* <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                            
                <div className="tabs-item">
                    <h3></h3>
                    <p></p>
                </div>

            </div> */}


            </section>
            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src="./img/services-images/emsculpt-2.png" />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            {/* <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                    <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button> */}
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <div className="tabs-item">
                                <h3></h3>
                                <p></p>
                            </div>


                        </div>

                        {/* <div id="result"  className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                    
                        <div className="tabs-item results">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path
                                d="M16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24558 29 12.5522 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24558 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16C3 12.5522 4.36964 9.24558 6.80761 6.80761C9.24558 4.36964 12.5522 3 16 3ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625C23.65 12.475 23.65 11.525 23.0625 10.9438C22.475 10.3625 21.525 10.3563 20.9438 10.9438L14.0063 17.8813L11.0688 14.9438C10.4813 14.3563 9.53125 14.3563 8.95 14.9438C8.36875 15.5312 8.3625 16.4813 8.95 17.0625L12.95 21.0625C13.5375 21.65 14.4875 21.65 15.0688 21.0625L23.0625 13.0625Z"
                                fill="#E23DB4" />
                            </svg></div>
                            <p></p>
                        </div>
                        
                    </div> */}

                        {/* <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                        <div class="tabs-item">
                            <div class="beforeafter_container">
                            <div class="beforeafter_images">
                                <img src="./img/services-images/beforeafter-1.png" />
                                <p>До</p>
                            </div>
                            <div class="beforeafter_images">
                                <img src="./img/services-images/beforeafter-1.png" />
                                <p>После</p>
                            </div>
                            </div>
                        </div>
                    </div> */}

                        {/* <div className='faq-container'>
                        <div>
                        <h3>Часто задаваемые вопросы</h3>
                        </div>

                            <button class="accordion" onClick={activateFaqTab} id="faq-1">Что такое кавитация в прессотерапия?</button>
                            <div class="panel" style={faqTab === 'faq-1' ? { display: 'block' } : { display: 'none' }}>
                            <p>Прессотерапия - это процедура, включающая последовательное сжатие и расширение манжет, приложенных к различным частям тела, для стимуляции кровообращения и лимфодренажа. Может включать подогрев.я с целлюлитом.</p>
                            </div>
                    </div> */}

                    </div>
                </div>
            </section>
            <Footer />
        </>

    );
};


export default LaserEpil;